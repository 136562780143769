export enum PopupType {
  unset = "",
  LivestreamFull = "LivestreamFull",
  LivestreamExpired = "LivestreamExpired",
  LeaveGameSession = "LeaveGameSession",
  LeaveGameSessionGameUnable = "LeaveGameSessionGameUnable",
  Unfriend = "Unfriend",
  UnSaved = "UnSaved",
  CongratulationsShop = "CongratulationsShop",
  TransactionStatus = "TransactionStatus",
  RechargeNow = "RechargeNow",
  PaymentSuccessful = "PaymentSuccessful",
  PaymentFail = "PaymentFail",
  Welcome = "Welcome",
  UnsupportedDevice = "UnsupportedDevice",
  UnsupportedBrowser = "UnsupportedBrowser",
  PersonalizeProfile = "PersonalizeProfile",
  CookieNotice = "CookieNotice",
  CookieConfigure = "CookieConfigure",
  Onboarding = "Onboarding",
  AddHomeScreen = "AddHomeScreen",
  Share = "Share",
  ShareAchievement = "ShareAchievement",
  WelcomeBack = "WelcomeBack",
  AccountBacklist = "AccountBacklist",
  AchievementDetail = "AchievementDetail",
  Maintenance = "Maintenance",
  NoInternet = "NoInternet",
  AppUpdated = "AppUpdated",
  DailyReward = "DailyReward",
  Inventory = "Inventory",
  OutOfGems = "OutOfGems",
  UserRating = "UserRating",
  PurchaseResult = "PurchaseResult",
  ErrorTenantMismatch = "ErrorTenantMismatch",
  InAppNotification = "InAppNotification",
}

export enum PopupPriority {
  lowest = -1,
  unset = 0,
  low = 1,
  medium = 2,
  high = 3,
}

export const popupWithPriority: {
  [key: string]: PopupPriority;
} = {
  [PopupType.unset]: PopupPriority.unset,
  [PopupType.LivestreamFull]: PopupPriority.unset,
  [PopupType.LivestreamExpired]: PopupPriority.unset,
  [PopupType.LeaveGameSession]: PopupPriority.unset,
  [PopupType.LeaveGameSessionGameUnable]: PopupPriority.unset,
  [PopupType.Unfriend]: PopupPriority.unset,
  [PopupType.UnSaved]: PopupPriority.unset,
  [PopupType.CongratulationsShop]: PopupPriority.unset,
  [PopupType.TransactionStatus]: PopupPriority.unset,
  [PopupType.RechargeNow]: PopupPriority.lowest,
  [PopupType.PaymentSuccessful]: PopupPriority.unset,
  [PopupType.PaymentFail]: PopupPriority.unset,
  [PopupType.Welcome]: PopupPriority.low,
  [PopupType.UnsupportedDevice]: PopupPriority.high,
  [PopupType.UnsupportedBrowser]: PopupPriority.high,
  [PopupType.PersonalizeProfile]: PopupPriority.unset,
  [PopupType.CookieNotice]: PopupPriority.unset,
  [PopupType.CookieConfigure]: PopupPriority.unset,
  [PopupType.Onboarding]: PopupPriority.unset,
  [PopupType.AddHomeScreen]: PopupPriority.low,
  [PopupType.Share]: PopupPriority.unset,
  [PopupType.WelcomeBack]: PopupPriority.unset,
  [PopupType.AchievementDetail]: PopupPriority.lowest,
  [PopupType.DailyReward]: PopupPriority.low,
  [PopupType.ShareAchievement]: PopupPriority.unset,
  [PopupType.InAppNotification]: PopupPriority.lowest,
};

export const LIMIT_BUTTON_SETTINGS = 52;
