import { DependencyList, useEffect, useRef } from "react";

const useEventListener = (
  eventName: string,
  handler: Function,
  dependencies: DependencyList = [],
) => {
  const savedHandler = useRef<Function>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = window && window.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: Event) => savedHandler.current?.(event);
      if (eventName === "orientationchange") {
        handler();
      }
      // Add event listener
      window.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        window.removeEventListener(eventName, eventListener);
      };
    },
    // Re-run if eventName or dependencies change
    [eventName, ...dependencies],
  );
};

export default useEventListener;
