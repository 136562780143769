import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { Query } from "../../../legacyGraphql/graphql";
import { getContestsGQL } from "../../queries/contests";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const getContests = async (
  options: Partial<QueryOptions> = {},
): Promise<Query["contests"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getContestsGQL,
    variables: {},
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.contests;
};
