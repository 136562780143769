import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getAchievements } from "../legacyGraphql/resolvers/queries/achievements";
import { differenceWith, filter, find, isEqual, map, sortBy } from "lodash-es";
import { Achievement, Achievement_Type } from "../legacyGraphql/graphql";
import { AppDispatch, AppThunk, RootState } from "../store";
import { compact } from "@apollo/client/utilities";
import { AchievementItemType } from "../types/achievement";
import { getAchievementsData } from "../models/achievements/achievements";
import { setNotificationError } from "./alert";
import { ACHIEVEMENTS_ORDER } from "../constants/achievements";

type Achievements = {
  achievementProfileData: AchievementItemType[] | undefined;
  achievementUserData: AchievementItemType[] | undefined;
  beforePlayAchievements: Achievement[] | undefined;
  afterPlayAchievements: Achievement[] | undefined;
};

const initialState = {
  beforePlayAchievements: undefined,
  afterPlayAchievements: undefined,
  achievementProfileData: undefined,
  achievementUserData: undefined,
} as Achievements;

export const resetAchievementState = createAction("achievement/resetState");

export const achievementSlice = createSlice({
  name: "achievements",
  initialState: initialState,
  reducers: {
    setAchievementsBeforePlay: (
      state,
      action: PayloadAction<{ achievements: Achievement[] | undefined }>,
    ) => {
      state.beforePlayAchievements = action.payload.achievements;
    },
    setAchievementsAfterPlay: (
      state,
      action: PayloadAction<{ achievements: Achievement[] | undefined }>,
    ) => {
      state.afterPlayAchievements = action.payload.achievements;
    },
    setAchievementsData: (
      state,
      action: PayloadAction<{
        achievementsData: AchievementItemType[] | undefined;
      }>,
    ) => {
      state.achievementProfileData = action.payload.achievementsData;
    },
    setAchievementUserData: (
      state,
      action: PayloadAction<{
        achievementsData: AchievementItemType[] | undefined;
      }>,
    ) => {
      state.achievementUserData = action.payload.achievementsData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAchievementsData.fulfilled, (state, action) => {
        const userId = action.meta.arg.userId;
        const items = action.payload;
        if (userId) {
          state.achievementUserData = items;
        } else state.achievementProfileData = items;
      })
      .addCase(fetchAchievementsData.rejected, (state) => {
        state.achievementUserData = undefined;
        state.achievementProfileData = undefined;
      })
      .addCase(resetAchievementState, () => initialState);
  },
});

export const {
  setAchievementsBeforePlay,
  setAchievementsAfterPlay,
  setAchievementsData,
  setAchievementUserData,
} = achievementSlice.actions;

export const clearAchievements = (): AppThunk => (dispatch) => {
  dispatch(setAchievementsBeforePlay({ achievements: undefined }));
  dispatch(setAchievementsAfterPlay({ achievements: undefined }));
};

export const fetchAchievements =
  (when: "beforePlay" | "afterPlay"): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getAchievements();
      if (when === "beforePlay") {
        dispatch(setAchievementsBeforePlay({ achievements: response.items }));
      } else {
        dispatch(setAchievementsAfterPlay({ achievements: response.items }));
      }
    } catch (error) {
      console.error(error);
      if (when === "beforePlay") {
        dispatch(setAchievementsBeforePlay({ achievements: [] }));
      } else {
        dispatch(setAchievementsAfterPlay({ achievements: [] }));
      }
    }
  };

export const selectEarnedAchievementsData = createSelector(
  (state: RootState) => state.achievements,
  (achievements: Achievements) => {
    if (
      achievements.beforePlayAchievements?.length &&
      achievements.afterPlayAchievements?.length
    ) {
      const achievementsEarned = differenceWith(
        achievements.afterPlayAchievements,
        achievements.beforePlayAchievements,
        (objA, objB) => {
          return isEqual(objA, objB);
        },
      );
      return sortBy(
        filter(achievementsEarned, (item: Achievement) =>
          [
            Achievement_Type.BattlesWon,
            Achievement_Type.BattleStreak,
            Achievement_Type.Challenger,
            Achievement_Type.Completionist,
            Achievement_Type.TournamentsParticipation,
            Achievement_Type.BattleFriendsPlayed,
          ].includes(item.id),
        ),
        "id",
      );
    }
  },
);

export const fetchAchievementsData = createAsyncThunk(
  "achievements/fetchAchievementsData",
  async (
    params: {
      userId?: string;
    },
    { dispatch, signal },
  ) => {
    const { userId } = params;
    try {
      const response = await getAchievements(userId, {
        fetchPolicy: "network-only",
        context: {
          fetchOptions: {
            signal,
          },
        },
      });
      const achievementsData = getAchievementsData(response.items);
      return achievementsData;
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
      throw e;
    }
  },
);

export const upgradedAchievementsSelector = createSelector(
  (state: RootState) => state.achievements,
  (achievement: Achievements) => {
    if (
      !achievement.afterPlayAchievements ||
      !achievement.beforePlayAchievements
    )
      return;

    const upgradedAchievements = compact(
      map(achievement.afterPlayAchievements, (a) => {
        const beforePlayAchievementItem = find(
          achievement.beforePlayAchievements,
          (ba) => ba.id === a.id,
        );
        if (!beforePlayAchievementItem) return;
        if (a.currentTier > beforePlayAchievementItem.currentTier) {
          return a;
        }
      }),
    );

    return sortBy(
      filter(upgradedAchievements, (item: Achievement) =>
        [
          Achievement_Type.BattlesWon,
          Achievement_Type.BattleStreak,
          Achievement_Type.Challenger,
          Achievement_Type.Completionist,
          Achievement_Type.TournamentsParticipation,
          Achievement_Type.BattleFriendsPlayed,
        ].includes(item.id),
      ),
      "id",
    );
  },
);

const achievementProfileData = (state: RootState) =>
  state.achievements.achievementProfileData;
const achievementUserData = (state: RootState) =>
  state.achievements.achievementUserData;

export const selectAchievementData = createSelector(
  [
    achievementProfileData,
    achievementUserData,
    (state: RootState, userId?: string) => userId,
  ],
  (achievementProfileData, achievementUserData, userId) => {
    const orderAchievement = (achievement?: AchievementItemType[]) =>
      sortBy(
        filter(
          achievement,
          (item) =>
            ACHIEVEMENTS_ORDER.includes(item.id) && item.currentTier < 4,
        ),
        (item) => ACHIEVEMENTS_ORDER.indexOf(item.id),
      );

    return orderAchievement(
      userId ? achievementUserData : achievementProfileData,
    );
  },
);

export const selectBattleStreak = createSelector(
  [
    (state: RootState) => state.achievements.afterPlayAchievements,
    (state: RootState) => state.achievements.beforePlayAchievements,
    (state: RootState, timing: string) => timing,
  ],
  (afterPlayAchievements, beforePlayAchievements, timing) => {
    const achievements =
      timing === "after" ? afterPlayAchievements : beforePlayAchievements;
    const battleStreak = achievements?.find(
      (item) => item.id === Achievement_Type.BattleStreak,
    )?.progress;

    return battleStreak;
  },
);

export default achievementSlice.reducer;
