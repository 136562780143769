import { compact, concat } from "lodash-es";
import Rank1Icon from "../assets/competition/rank1_2x.webp";
import Rank2Icon from "../assets/competition/rank2_2x.webp";
import Rank3Icon from "../assets/competition/rank3_2x.webp";
import OutTopIcon from "../assets/competition/out-top_2x.webp";
import { getGlobalLeaderboardProfile } from "../legacyGraphql/resolvers/queries/globalLeaderboards";
import {
  GlobalLeaderboardItem,
  Leaderboard_Type,
  TimeFrame,
  TournamentInfo,
} from "../legacyGraphql/graphql";
import { LeaderboardType, TimeLine } from "../constants/leaderboards";
import { TFunction } from "i18next";
import { Month } from "../constants/month";
import { QueryOptions } from "@apollo/client";
import { formatScoreByMomentType } from "./gamesession/gamesession";
import {
  Activity,
  GlobalLeaderboardType,
  Leaderboard,
  LeaderboardActivityData,
  LeaderboardUser,
  LeaderboardUserNotificationData,
  User,
  UserLeaderboardActivityData,
} from "../graphql/graphql";
import { LeaderboardUser as LeaderboardUserOld } from "../legacyGraphql/graphql";
import { IGlobalLeaderboards } from "../slices/globalLeaderboard";

export class GlobalLeaderboardsMd {
  static getGlobalLeaderboardProfile = (
    endDate: string,
    leaderboardType: string,
    options: Partial<QueryOptions> = {},
  ) => getGlobalLeaderboardProfile(endDate, leaderboardType, options);

  static updateRankForPlayer = (
    leaderboard: LeaderboardUser[] | LeaderboardUserOld[] | undefined,
  ) => {
    if (!leaderboard) return null;
    return leaderboard.map(
      (leaderboard: LeaderboardUser | LeaderboardUserOld, idx) => ({
        ...leaderboard,
        rank: idx + 1,
      }),
    );
  };

  static getListOutTop3Lb = (
    user: User | undefined | null,
    currentLb: Leaderboard | GlobalLeaderboardItem | null | undefined,
  ) => {
    if (!currentLb || !user) return null;
    const isOldLb = currentLb?.__typename === "GlobalLeaderboardItem";

    const newLb = this.updateRankForPlayer(
      isOldLb
        ? (currentLb as GlobalLeaderboardItem)?.leaderboard
        : (currentLb as Leaderboard)?.items,
    );
    if (!newLb || newLb.length < 4) return null;

    const outTop3LbWithoutMyRanking = newLb.slice(3).filter((player) => {
      const isOldLb = !!(player as LeaderboardUserOld).id;
      if (isOldLb) {
        return (player as LeaderboardUserOld)?.id !== user?.id;
      } else return (player as LeaderboardUser).user?.id !== user?.id;
    });

    const myRank = isOldLb
      ? {
          banner: "",
          id: user.id,
          avatar: user.avatar,
          username: user.username,
          xp: user.xp,
          rank: (currentLb as GlobalLeaderboardItem)?.myRank + 1,
          score: (currentLb as GlobalLeaderboardItem)?.myScore,
          __typename: "LeaderboardUser",
        }
      : {
          rank: (currentLb as Leaderboard)?.rank + 1,
          score: (currentLb as Leaderboard)?.score,
          user: {
            id: user.id,
            avatar: user.avatar,
            username: user.username,
            xp: user.xp,
          } as User,
          __typename: "LeaderboardUser",
        };

    const isMyRankingValid = myRank?.rank > 3 && myRank?.score !== -1;
    return compact(
      concat([isMyRankingValid ? myRank : null], outTop3LbWithoutMyRanking),
    );
  };

  static getTop3Lb = (
    leaderboard?: LeaderboardUser[] | LeaderboardUserOld[],
  ) => {
    if (!leaderboard) return null;
    const listTop3Lb = leaderboard.slice(0, 3);
    const sortedRank = [2, 1, 3];
    return sortedRank.map((rank) => {
      const user = listTop3Lb.find((_, i) => i === rank - 1);
      return { ...user, rank, type: "top3" };
    }) as LeaderboardUser[] | LeaderboardUserOld[];
  };

  static getShareBackground = (rank: number) => {
    switch (rank) {
      case 1:
        return Rank1Icon;
      case 2:
        return Rank2Icon;
      case 3:
        return Rank3Icon;
      default:
        return OutTopIcon;
    }
  };

  static getTitleLb = (
    t: TFunction,
    lbActivity?: Activity,
    lbNotificationData?: LeaderboardUserNotificationData,
  ) => {
    const date = new Date(
      lbNotificationData
        ? lbNotificationData?.endDate
        : (
            lbActivity?.data as
              | LeaderboardActivityData
              | UserLeaderboardActivityData
          ).endDate,
    );

    const thisMonth = Month[date.getUTCMonth()];
    const typeLeaderboard =
      (lbActivity?.data as LeaderboardActivityData)?.type ===
        "global-leaderboard-weekly" ||
      (lbActivity?.data as UserLeaderboardActivityData)?.repeatCycle ===
        "weekly" ||
      lbNotificationData?.timeFrame === TimeFrame.Weekly
        ? TimeLine.Weekly
        : thisMonth;

    return t(`competition_${typeLeaderboard} Leaderboard`);
  };

  static getLeaderboardType = (type?: string) => {
    switch (type) {
      case "global-leaderboard-weekly":
        return Leaderboard_Type.Weekly;
      case "global-leaderboard-monthly":
        return Leaderboard_Type.Monthly;
      case "global-leaderboard-contests":
        return Leaderboard_Type.Contest;
      default:
        return Leaderboard_Type.Weekly;
    }
  };

  static convertTournamentToGlobalLeaderboard = (
    tournament?: TournamentInfo,
    myUserId?: string,
  ) => {
    if (!tournament) return undefined;
    const { expiredAt, leaderboards, moment, myRank } = tournament;

    const myScore =
      leaderboards.find((data) => data.userId === myUserId)?.score ?? 0;
    const formattedLb = leaderboards.map((data, idx) => ({
      user: {
        ...data.user,
      } as any,
      rank: idx + 1,
      score: formatScoreByMomentType(data.score as number, moment.type),
      __typename: "LeaderboardUser",
    }));

    return {
      endDate: expiredAt,
      items: formattedLb,
      rank: myRank ? myRank - 1 : undefined,
      score: formatScoreByMomentType(myScore as number, moment.type),
      total: formattedLb.length,
      __typename: "Leaderboard",
    } as Leaderboard;
  };

  static getCurrentLeaderboard = (
    activeTab: string,
    globalLeaderboards?: IGlobalLeaderboards,
    historyGlobalLeaderboard?: Leaderboard | GlobalLeaderboardItem | null,
    tournamentLeaderboard?: Leaderboard | null,
    leaderboardType?: LeaderboardType,
  ) => {
    const globalLeaderboardType =
      GlobalLeaderboardsMd.globalLeaderboardType(activeTab);

    switch (leaderboardType) {
      case LeaderboardType.GlobalLeaderboard:
        if (!globalLeaderboards) return null;
        return globalLeaderboards[globalLeaderboardType];
      case LeaderboardType.Tournament:
      case LeaderboardType.TournamentOnGoing:
        return tournamentLeaderboard;
      case LeaderboardType.HistoryGlobalLeaderboard:
        return historyGlobalLeaderboard;
      default:
        return null;
    }
  };

  static getItemSize = (index: number) => {
    if (index === 0) {
      return 95;
    }
    return 70;
  };

  static globalLeaderboardType = (activeTab: string) => {
    switch (activeTab) {
      case "All time":
        return GlobalLeaderboardType.AllTime;
      case "Monthly":
        return GlobalLeaderboardType.Monthly;
      case "Weekly":
        return GlobalLeaderboardType.Weekly;
      default:
        return GlobalLeaderboardType.Weekly;
    }
  };
}
