import { Inventory, InventoryCategory } from "../../graphql/graphql";

export const getInventoryImage = (inventory?: Inventory) => {
  if (inventory?.data?.__typename === "InventoryBundleData") {
    return inventory?.data.previewUrl;
  } else return inventory?.data.url;
};

export const getCategoriesOwned = (userInventories: Inventory[]) => {
  return userInventories.filter((i) => !i.isBundle) as Inventory[];
};

export const getTitleByCategories = (category: InventoryCategory) => {
  if (category === InventoryCategory.Avatar) {
    return "shop_Avatars Owned";
  } else {
    return "Title Owned";
  }
};

export const getCategoryTitleKey = (category: string) => {
  const lowerString = category.toLowerCase() + "s";
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
};
