import { AnalyticsEvent, WebVitalsName } from "./constants/analytics";
import { AnalyticsGA4 } from "./models/analyticsGA4";

// event values can only contain integers
const getEventValueFromMetric = (metric: { name: string; value: number }) => {
  if (metric.name === "CLS") {
    return Math.round(metric.value * 1000);
  }
  return Math.round(metric.value);
};

const getEventNameFromMetric = (metric: { name: string; value: number }) => {
  if (metric.name === WebVitalsName.FCP) {
    return AnalyticsEvent.WebVitalsFcp;
  } else if (metric.name === WebVitalsName.LCP) {
    return AnalyticsEvent.WebVitalsLcp;
  } else if (metric.name === WebVitalsName.FID) {
    return AnalyticsEvent.WebVitalsFid;
  } else if (metric.name === WebVitalsName.CLS) {
    return AnalyticsEvent.WebVitalsCls;
  } else if (metric.name === WebVitalsName.TTFB) {
    return AnalyticsEvent.WebVitalsTtfb;
  }
  return metric.name;
};

const reportHandler = (metric: { name: string; value: number }) => {
  AnalyticsGA4.sendWebVitalsToAnalytics(
    getEventNameFromMetric(metric),
    metric.name,
    getEventValueFromMetric(metric),
  );
};

export default reportHandler;
