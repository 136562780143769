import newMeasurementScheduler from "@applandstream/streaming-view-sdk/measurement";
import { STREAM_ENDPOINT } from "../constants/endpoint";
import {
  SingleMeasurement,
  MeasurementProgress,
} from "@applandstream/streaming-view-sdk/measurements/measurementScheduler";
import {
  Measurement,
  GameAvailability,
  PredictedGameExperienceResponse,
} from "@applandstream/streaming-view-sdk/measurements/service/networkConnectivity";

let measurementScheduler: {
  startMeasuring: () => Promise<SingleMeasurement>;
  stopMeasuring: () => Promise<SingleMeasurement>;
  resetMeasures: () => Promise<void>;
  getConnectivityInfo: () => Promise<object | Measurement>;
  getDeviceInfo: () => Promise<{ deviceInfoId: string; userId: string }>;
  getMeasurementProgress: () => Promise<MeasurementProgress>;
  getGameAvailability: () => Promise<{ apps: GameAvailability[] }>;
  getPredictedGameExperiences: (
    _pollingInterval?: number | undefined,
  ) => Promise<PredictedGameExperienceResponse>;
} | null = null;

export const getMeasurementScheduler = (accessToken?: string | null) => {
  if (!measurementScheduler) {
    measurementScheduler = newMeasurementScheduler({
      apiEndpoint: STREAM_ENDPOINT,
      interval: 30 * 1000,
      navigatorConnection: (window.navigator.connection ||
        window.navigator.connection ||
        (window.navigator as any).webkitConnection ||
        {}) as any,
      userConfiguration: {
        vendor: "",
      }, // Add userConfiguration property
      userAuthToken: accessToken || "",
    });
  }

  return measurementScheduler;
};
