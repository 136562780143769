import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AppDispatch, AsyncAppThunk, RootState } from "../store";
import { QueryOptions } from "@apollo/client";
import { OnmoStorage } from "../models/onmoStorage";
import { Appland } from "../models/appland";
import {
  Game as OldGame,
  GameStatus,
  GameType,
  NGame,
} from "../legacyGraphql/graphql";
import { GamesTab } from "../constants/games";
import { filter, orderBy, sortBy, uniq } from "lodash-es";
import { setNotificationError } from "./alert";
import {
  getGame,
  listUserMostPlayedGames,
} from "../legacyGraphql/resolvers/queries/games";
import { filterGameByCategory, orderByTimesPlayed } from "../models/game/game";
import { ONMO_CONFIG } from "../constants/onmo";
import { queryGames } from "../graphql/resolvers/queries/games";
import { Game } from "../graphql/graphql";

export interface Region {
  edgeRegionId: string;
  name: string;
}

interface IGameSlice {
  listGame: null | Game[];
  topGames: null | NGame[];
  userTopGames: null | NGame[];
  gameExperiences: GameNetWork[] | null;
  activeCategory: string;
  game?: OldGame;
  streamingRegion: Region[] | null;
  currentStreamingRegion?: string;
}

export const DesiredOrder = [
  "Race & Chase",
  "Sports",
  "Puzzle",
  "Shooters",
  "Casual",
  "Fighting & Action",
];

export interface GameNotAvailable {
  appId: number;
  available: boolean;
}

export interface GameNetWork {
  appId: number;
  score: number;
  available: boolean;
}

const onmoStorage = ONMO_CONFIG?.auth?.forceSessionAuth
  ? sessionStorage
  : localStorage;

const initialState = {
  listGame: JSON.parse(onmoStorage.getItem("listGames") || "null"),
  gameExperiences: null,
  topGames: null,
  userTopGames: null,
  activeCategory: "",
  game: undefined,
  streamingRegion: null,
  currentStreamingRegion:
    JSON.parse(onmoStorage.getItem("region") || "null") || undefined,
} as IGameSlice;

export const resetGameState = createAction("game/resetState");

export const gameSlice = createSlice({
  name: "game",
  initialState: initialState,
  reducers: {
    updateListGames: (state, action: PayloadAction<{ listGame: Game[] }>) => {
      state.listGame = action.payload.listGame;
    },
    updateListTopGames: (
      state,
      action: PayloadAction<{ topGames: NGame[] | null }>,
    ) => {
      state.topGames = action.payload.topGames;
    },
    updateUserTopGames: (
      state,
      action: PayloadAction<{ topGames: NGame[] | null }>,
    ) => {
      state.userTopGames = action.payload.topGames;
    },
    updateGamesExperience: (
      state,
      action: PayloadAction<{ gameExperiences: GameNetWork[] }>,
    ) => {
      state.gameExperiences = action.payload.gameExperiences;
    },
    setActiveCategory: (state, action) => {
      const { activeCategory } = action.payload;
      state.activeCategory = activeCategory;
    },
    updateGame: (state, action: PayloadAction<{ game?: OldGame }>) => {
      state.game = action.payload.game;
    },
    updateStreamingRegion: (
      state,
      action: PayloadAction<{
        streamingRegion: Region[];
      }>,
    ) => {
      state.streamingRegion = action.payload.streamingRegion || null;
    },
    updateCurrentStreamingRegion: (
      state,
      action: PayloadAction<{
        currentStreamingRegion: string | undefined;
      }>,
    ) => {
      state.currentStreamingRegion = action.payload.currentStreamingRegion;
      OnmoStorage.setRegion(
        JSON.stringify(action.payload.currentStreamingRegion),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGame.rejected, (state) => {
        state.game = undefined;
      })
      .addCase(fetchGame.fulfilled, (state, action) => {
        state.game = action.payload;
      })
      .addCase(fetchListTopGames.fulfilled, (state, action) => {
        const userId = action.meta.arg.userId;
        const items = action.payload;
        if (userId) {
          state.userTopGames = items;
        } else state.topGames = items;
      })
      .addCase(fetchListTopGames.rejected, (state) => {
        state.topGames = null;
        state.userTopGames = null;
      })
      .addCase(resetGameState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateListGames: updateListGamesAction,
  updateListTopGames: updateListTopGamesAction,
  updateUserTopGames,
  updateGamesExperience: updateGamesExperienceAction,
  setActiveCategory,
  updateGame,
  updateStreamingRegion,
  updateCurrentStreamingRegion,
} = gameSlice.actions;

export const fetchListGame =
  (option?: Partial<QueryOptions>): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const listGame = await queryGames(option, {
        status: [GameStatus.Live],
        page: { limit: 100, offset: 0 },
      });
      const newListGame = orderBy(
        listGame,
        [(game) => game.title.toLowerCase()],
        ["asc"],
      );
      OnmoStorage.setListGames(JSON.stringify(newListGame));
      dispatch(updateListGamesAction({ listGame: newListGame }));
    } catch (e) {
      dispatch(updateListGamesAction({ listGame: [] }));
      console.error(e);
    }
  };

export const fetchGameExperiences = (): AsyncAppThunk => async (dispatch) => {
  try {
    const gameExperiences = await Appland.getPredictedGameExperiences();

    dispatch(
      updateGamesExperienceAction({
        gameExperiences: gameExperiences,
      }),
    );
  } catch (e) {
    console.error(e);
    dispatch(
      updateGamesExperienceAction({
        gameExperiences: [],
      }),
    );
  }
};

export const fetchListTopGames = createAsyncThunk(
  "game/fetchListTopGames",
  async (
    params: {
      userId?: string;
    },
    { dispatch, signal },
  ) => {
    const { userId } = params;
    try {
      const mostPlayedGames = await listUserMostPlayedGames(
        {
          fetchPolicy: "network-only",
          context: {
            fetchOptions: {
              signal,
            },
          },
        },
        userId,
      );

      const filterGamePlayed = mostPlayedGames.filter(
        (game: NGame) => game.skill && game.gamesPlayedByUser > 2,
      );
      return filterGamePlayed;
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
      throw e;
    }
  },
);

export const fetchGame = createAsyncThunk(
  "game/fetchGame",
  async (gameId: string, { dispatch, signal }) => {
    try {
      const game = await getGame(
        { id: gameId || "" },
        {
          fetchPolicy: "network-only",
          context: {
            fetchOptions: {
              signal,
            },
          },
        },
      );
      return game;
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
      throw e;
    }
  },
);

export const getRegions = (): AsyncAppThunk => async (dispatch) => {
  try {
    const response = await fetch(
      "https://streaming-api.appland.se/api/streaming-games/regions",
    );
    const regions = await response.json();
    const defaultRegion = [{ edgeRegionId: "", name: "Default (Automatic)" }];
    dispatch(
      updateStreamingRegion({
        streamingRegion: defaultRegion.concat(regions.regions),
      }),
    );
  } catch (e) {
    console.error(e);
  }
};

const listGame = (state: RootState) => state.game.listGame;
const activeCategory = (state: RootState) => state.game.activeCategory;
const currentGame = (state: RootState) => state.game.game;
const gameExperiences = (state: RootState) => state.game.gameExperiences;
const topGames = (state: RootState) => state.game.topGames;
const userTopGames = (state: RootState) => state.game.userTopGames;

export const selectListGamesByCategory = createSelector(
  [listGame, activeCategory],
  (listGame, activeCategory) => {
    if (!activeCategory) {
      return orderByTimesPlayed(listGame);
    } else if (activeCategory === GamesTab.AllCategories) {
      return orderByTimesPlayed(listGame);
    } else {
      const listGameByCategory = filterGameByCategory(listGame, activeCategory);
      return orderByTimesPlayed(listGameByCategory);
    }
  },
);

export const selectListTabGames = createSelector(listGame, (listGame) => {
  const filterGameByCategory = uniq(listGame?.map((game) => game.category));
  const originArray = [
    ...uniq(filterGameByCategory.flatMap((item) => JSON.parse(item))),
  ];
  return [
    GamesTab.AllCategories,
    ...sortBy(originArray, (item) => DesiredOrder.indexOf(item)),
  ];
});

export const selectIsGameHasBattle = createSelector(
  [listGame, (state: RootState, gameId?: string) => gameId],
  (listGame, gameId) => {
    const game = listGame?.find((game) => game.id === gameId);
    return game?.hasBattle;
  },
);

export const selectListTopGame = createSelector(
  [
    topGames,
    userTopGames,
    (
      state: RootState,
      options: {
        userId: string;
      },
    ) => options,
  ],
  (topGames, userTopGames, { userId }) => {
    if (userId) {
      return userTopGames;
    } else return topGames;
  },
);

export const selectGameNetworkScore = createSelector(
  [gameExperiences, (state: RootState, currentGame?: Game) => currentGame],
  (gameExperiences, currentGame) => {
    if (currentGame?.type === GameType.Html) return 5;
    if (!gameExperiences) return null;

    const gameNetWork = gameExperiences.find(
      (o) => o.appId === parseInt(currentGame?.id || ""),
    );
    return Math.round(gameNetWork?.score || 0);
  },
);

export const selectGameGoLive = createSelector(listGame, (listGame) => {
  return filter(
    listGame,
    (game) => game?.type === GameType.Stream && game?.hasSolo,
  );
});

export const selectListHasBattleGame = createSelector(listGame, (listGame) => {
  return listGame?.filter((game) => game.hasBattle) || [];
});

export const selectGameById = createSelector(
  [listGame, (state: RootState, gameId?: string) => gameId],
  (listGame, gameId) => {
    return listGame?.find((game) => game.id === gameId);
  },
);

export const selectChallengesSorted = createSelector([currentGame], (game) => {
  if (!game) return;
  return orderBy(game.soloChallenges, "order", "asc");
});

export const selectCurrentMoment = createSelector(
  [selectChallengesSorted, currentGame],
  (listMomentSorted, game) => {
    if (!game) return;
    const currentMoment = listMomentSorted?.find((moment) => {
      return !moment?.isCompleted;
    });
    return currentMoment || listMomentSorted?.[0];
  },
);

export const selectNbChallengeUnlock = createSelector(
  [selectChallengesSorted],
  (listMomentSorted) => {
    if (!listMomentSorted) return 0;
    const currentMomentIndex = listMomentSorted?.findIndex(
      (moment) => !moment.isCompleted,
    );
    return currentMomentIndex < 0
      ? listMomentSorted.length - 1
      : currentMomentIndex;
  },
);
export default gameSlice.reducer;
