import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { getUserNotificationsGQL } from "../../queries/notificationQueryGql";
import { Query } from "../../graphql";

const apolloClient = apollo.getInstance();

export const getUserNotifications = async (
  options: Partial<QueryOptions> = {},
  params: {
    userId?: string;
  },
): Promise<Query["users"]["items"][0]["notifications"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserNotificationsGQL,
    variables: params,
  });

  return res.data.user.notifications;
};
