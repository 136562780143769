import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { filter } from "lodash-es";
import { AsyncAppThunk } from "../store";
import { getUserNotifications as getUserNotificationsAPI } from "../graphql/resolvers/queries/notificationQueryResolver";
import { UserNotification } from "../graphql/graphql";
import { NotificationFactory } from "../models/notificationFactory";

interface IInAppNotification {
  notiState: "show" | "close" | "fadeOut";
  userNotifications: UserNotification[] | null;
  appUpdated: boolean;
  data: UserNotification | null;
  queue: UserNotification[];
}

const initialState = {
  notiState: "close",
  appUpdated: false,
  data: null,
  queue: [],
  userNotifications: [],
} as IInAppNotification;

export const resetInAppNotificationState = createAction(
  "inAppNotification/resetState",
);

export const inAppNotificationSlice = createSlice({
  name: "inAppNotification",
  initialState: initialState,
  reducers: {
    closeUserNotification: (state) => {
      state.userNotifications = [];
    },
    setNotification: (
      state,
      action: PayloadAction<{ data?: UserNotification }>,
    ) => {
      if (!action.payload.data) return;

      const incomingNotiId = action.payload.data.id;
      if (state.data?.id === incomingNotiId) return;

      if (state.notiState === "show") {
        // if notification is showing, add new notification to queue
        state.queue.push(action.payload.data);
      } else {
        state.data = action.payload.data;
        state.notiState = "show";
        state.queue.shift();
      }
    },
    setUserNotification: (
      state,
      action: PayloadAction<{ userNotifications: UserNotification[] | null }>,
    ) => {
      state.userNotifications = action.payload.userNotifications;
    },
    setAppUpdated: (state, action: PayloadAction<{ appUpdated: boolean }>) => {
      state.appUpdated = action.payload.appUpdated;
    },
    closeNotification: (state) => {
      state.notiState = "close";
      state.data = null;
    },
    hideNotification: (state) => {
      state.notiState = "fadeOut";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetInAppNotificationState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  setNotification,
  closeNotification,
  setUserNotification,
  closeUserNotification,
  hideNotification,
  setAppUpdated,
} = inAppNotificationSlice.actions;

export const getUserNotifications =
  (): AsyncAppThunk => async (dispatch, getState) => {
    const state = getState();
    if (!state.user.me) return;

    try {
      const userNotifications = await getUserNotificationsAPI(
        { fetchPolicy: "network-only" },
        {
          userId: state.user.me.id,
        },
      );
      const userNotificationsFilter = filter(
        userNotifications,
        (notif) => !NotificationFactory.isUserDeleted(notif),
      );
      dispatch(
        setUserNotification({ userNotifications: userNotificationsFilter }),
      );
    } catch (error) {
      dispatch(setUserNotification({ userNotifications: [] }));
      console.error(error);
    }
  };

export default inAppNotificationSlice.reducer;
