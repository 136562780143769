import { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";

interface IHelpShift {
  onClose: Function;
  isDataGameSession?: boolean;
}

export const HelpShift: FC<IHelpShift> = (props) => {
  const { onClose, isDataGameSession } = props;
  const { me } = useAppSelector((state) => state.user);
  const { gameSession } = useAppSelector((state) => state.gameSession);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) return;
    if (!me) {
      window.Helpshift("updateHelpshiftConfig");
      window.Helpshift("open");
      return;
    }
    window.helpshiftConfig.language =
      me.language === "telefonica_es" ? "es" : me.language;
    window.helpshiftConfig.userId = me.id;
    window.helpshiftConfig.userEmail = me.email;
    window.helpshiftConfig.userName = me.username;
    if (gameSession && isDataGameSession) {
      window.Helpshift("setCustomIssueFields", {
        game_id: {
          type: "singleline",
          value: gameSession?.moment.appId,
        },
        edge_node_id: {
          type: "singleline",
          value: gameSession?.edgeNodeId,
        },
        game_session_id: {
          type: "singleline",
          value: gameSession?.id,
        },
        moment_id: {
          type: "singleline",
          value: gameSession?.momentId,
        },
      });
    }
    window.Helpshift("updateHelpshiftConfig");
    window.Helpshift("open");
  }, [me]);

  useEffect(() => {
    const widgetToggleEventHandler = (data: { visible: boolean }) => {
      if (!data.visible) {
        onClose();
      }
      setIsOpen(data.visible);
    };
    window.Helpshift(
      "addEventListener",
      "widgetToggle",
      widgetToggleEventHandler,
    );

    return () => {
      window.Helpshift(
        "removeEventListener",
        "widgetToggle",
        widgetToggleEventHandler,
      );
    };
  }, [onClose]);

  return null;
};
