import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { Theme } from "../models/theme";
import { ThemeName } from "../constants/theme";
import { DEFAULT_THEME } from "../theme/theme";
import { AppThunk } from "../store";
import { System } from "../models/system";

const initialTheme = System.isProd ? Theme.getProdTheme() : DEFAULT_THEME;

const initialState = {
  isThemeLoaded: System.isProd,
  theme: initialTheme,
  isTestTheme: initialTheme?.app?.name !== ThemeName.Onmo && !System.isProd,
  isVodafone: initialTheme?.app?.name === ThemeName.VodafoneIndia,
  isDefaultPhoneLoginButton:
    initialTheme?.app?.name === ThemeName.VodafoneQatar ||
    initialTheme?.app?.name === ThemeName.OmanTelOman ||
    initialTheme?.app?.name === ThemeName.Arena ||
    initialTheme?.app?.name === ThemeName.AtomMyanmar,
};

export const resetThemeState = createAction("theme/resetState");

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    fetchTheme: (state, action) => {
      const theme = action.payload.theme;
      state.theme = theme;
      state.isThemeLoaded = true;
      state.isTestTheme = theme?.app?.name !== ThemeName.Onmo && !System.isProd;
      state.isVodafone = theme?.app?.name === ThemeName.VodafoneIndia;
      state.isDefaultPhoneLoginButton =
        theme?.app?.name === ThemeName.VodafoneQatar ||
        theme?.app?.name === ThemeName.OmanTelOman ||
        theme?.app?.name === ThemeName.Arena ||
        theme?.app?.name === ThemeName.AtomMyanmar;
    },
    setTextStyle: (
      state,
      action: PayloadAction<"default" | "medium" | "small">,
    ) => {
      const textStyle = action.payload;
      state.theme.theme.textStyle = textStyle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetThemeState, () => initialState);
  },
});

export const { fetchTheme: fetchThemeAction, setTextStyle } =
  themeSlice.actions;

export const fetchTheme = (): AppThunk => async (dispatch) => {
  // Theme is already loaded on prod
  if (System.isProd) return;

  try {
    const theme = await Theme.getTheme();
    dispatch(fetchThemeAction({ theme }));
  } catch (e) {
    console.log("Fail to fetch theme: ", e);
  }
};

// Action creators are generated for each case reducer function

export default themeSlice.reducer;
