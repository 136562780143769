import { FC, HTMLAttributes } from "react";
import clsx from "classnames";
import { useStyles } from "./GridX.style";
import { GridPadding, GridPaddingMapping } from "../../../constants/padding";

type GridDisplay = "flex" | "inline-flex" | "inline" | "block" | "grid";
type GridAlignItems =
  | "center"
  | "flex-start"
  | "flex-end"
  | "stretch"
  | "baseline";
type GridJustifyContent =
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly";
type GridSizing = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type GridFlexDirection = "row" | "column" | "row-reverse" | "column-reverse";

export interface IGridXProps {
  item?: boolean;
  container?: boolean;
  size?: GridSizing;
  spacing?: GridPadding;
  spacingLeft?: GridPadding;
  spacingTop?: GridPadding;
  spacingRight?: GridPadding;
  spacingBottom?: GridPadding;
  display?: GridDisplay;
  alignItems?: GridAlignItems;
  justifyContent?: GridJustifyContent;
  flexDirection?: GridFlexDirection;
  flexGrow?: number;
  gap?: GridPadding;
}

export const GridX: FC<IGridXProps & HTMLAttributes<HTMLDivElement>> = ({
  children,
  item,
  container,
  size,
  spacing,
  spacingLeft,
  spacingTop,
  spacingRight,
  spacingBottom,
  display,
  alignItems,
  justifyContent,
  flexDirection,
  flexGrow,
  gap,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <div
      {...otherProps}
      style={{
        gap: GridPaddingMapping[gap || "none"],
        alignItems,
        display,
        justifyContent,
        flexDirection,
        flexGrow,
        ...(spacingTop ? { paddingTop: GridPaddingMapping[spacingTop] } : {}),
        ...(spacingBottom
          ? { paddingBottom: GridPaddingMapping[spacingBottom] }
          : {}),
        ...(spacingLeft
          ? { paddingInlineStart: GridPaddingMapping[spacingLeft] }
          : {}),
        ...(spacingRight
          ? { paddingInlineEnd: GridPaddingMapping[spacingRight] }
          : {}),
        ...otherProps.style,
      }}
      className={clsx(
        classes.root,
        otherProps.className,
        spacing,
        {
          item,
          container,
        },
        size && `size-${size}`,
      )}
    >
      {children}
    </div>
  );
};

GridX.defaultProps = {
  spacing: "none",
  item: false,
  container: false,
  gap: "none",
};
