import { gql } from "graphql-tag";

export const purchaseInventoryGQL = gql`
  mutation PurchaseInventory($input: PurchaseInventoryInput!) {
    purchaseInventory(input: $input) {
      message
    }
  }
`;

export const applyInventoryGQL = gql`
  mutation ApplyInventory($input: ApplyInventoryInput!) {
    applyInventory(input: $input) {
      message
    }
  }
`;
