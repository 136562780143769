import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { IParamsInventories } from "../../../types/inventories";
import { Query } from "../../graphql";
import {
  getListInventoriesGQL,
  getUserInventoriesGQL,
} from "../../queries/inventories";

const apolloClient = apollo.getInstance();

export const fetchListInventories = async (
  options: Partial<QueryOptions> = {},
  params?: IParamsInventories,
): Promise<Query["inventories"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getListInventoriesGQL,
    variables: { input: params },
  });

  return res.data.inventories;
};

export const fetchUserInventories = async (
  options: Partial<QueryOptions> = {},
  params?: IParamsInventories,
): Promise<Query["me"]["inventories"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserInventoriesGQL,
    variables: { input: params },
  });

  return res?.data?.me?.inventories;
};
