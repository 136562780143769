import apollo from "../../../clients/apollo";
import { Mutation, PurchaseInventoryInput } from "../../graphql";
import {
  applyInventoryGQL,
  purchaseInventoryGQL,
} from "../../mutations/inventoriesMutationGql";

const apolloClient = apollo.getInstance();

export const purchaseInventory = async (
  input: PurchaseInventoryInput,
): Promise<Mutation["purchaseInventory"]> => {
  const res = await apolloClient.mutate({
    mutation: purchaseInventoryGQL,
    variables: { input },
  });

  return res.data.applyInventory;
};
export const applyInventory = async (
  inventoryId: string,
): Promise<Mutation["applyInventory"]> => {
  const res = await apolloClient.mutate({
    mutation: applyInventoryGQL,
    variables: { input: { inventoryId } },
  });

  return res.data.applyInventory;
};
