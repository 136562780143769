import apollo from "../../../clients/apollo";
import {
  createMutualFollowCodeGQL,
  followUserGQL,
  unfollowUserGQL,
} from "../../mutations/friends";
import { Mutation } from "../../../legacyGraphql/graphql";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const followUser = async (
  userId: string,
): Promise<Mutation["followUser"]> => {
  const res = await apolloClient.mutate({
    mutation: followUserGQL,
    variables: { followUserInput: { userId } },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.followUser;
};

export const unfollowUser = async (
  userId: string,
): Promise<Mutation["unfollowUser"]> => {
  const res = await apolloClient.mutate({
    mutation: unfollowUserGQL,
    variables: { unfollowUserInput: { userId } },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.unfollowUser;
};

export const createMutualFollowCode = async (): Promise<
  Mutation["createMutualFollowCode"]
> => {
  const res = await apolloClient.mutate({
    mutation: createMutualFollowCodeGQL,
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.createMutualFollowCode;
};
