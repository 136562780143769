import React, { HTMLAttributes, FC } from "react";
import clsx from "classnames";
import { useStyles } from "./Ripple.style";

interface IPropsRipple {
  type?: string;
  isDisable?: boolean;
}

const RippleComponent: FC<HTMLAttributes<HTMLDivElement> & IPropsRipple> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  const { type, isDisable, ...otherProps } = props;

  if (isDisable) {
    return <>{children}</>;
  }

  return (
    <div
      {...otherProps}
      className={clsx(
        classes.ripple,
        type === "button" ? "button" : type === "card" ? "card" : "",
        props.className,
      )}
    >
      {children}
    </div>
  );
};

export const Ripple = React.memo(RippleComponent);
