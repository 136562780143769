import { uniq } from "lodash-es";
import { isMobile } from "react-device-detect";
import {
  sessionStorage as amplifySessionStorage,
  defaultStorage,
} from "aws-amplify/utils";

import { PageRoutes } from "../constants/historyOnmo";
import { ONMO_CONFIG } from "../constants/onmo";
import { System } from "./system";
import { TierLevel } from "../legacyGraphql/graphql";
import { getCurrentRank } from "./userFactory/userFactory";
import { Time } from "./time";
import { Auth } from "./auth";

const onmoStorage = ONMO_CONFIG?.auth?.forceSessionAuth
  ? sessionStorage
  : localStorage;

export class OnmoStorage {
  static getAmplifyStorage = () => {
    return ONMO_CONFIG?.auth?.forceSessionAuth
      ? amplifySessionStorage
      : defaultStorage;
  };

  static setIsVerifyAge = (isVerify: string) => {
    onmoStorage.setItem("verifyAge", isVerify);
  };

  static setUserId = (userId: string) => {
    onmoStorage.setItem("userId", userId);
  };

  static getUserId = () => {
    return onmoStorage.getItem("userId");
  };

  static setUserIdpId = (userIdpId: string) => {
    onmoStorage.setItem("userIdpId", userIdpId);
  };

  static getUserIdpId = () => {
    return onmoStorage.getItem("userIdpId");
  };

  static setUserIdpName = (userIdpName: string) => {
    onmoStorage.setItem("userIdpName", userIdpName);
  };

  static getUserIdpName = () => {
    return onmoStorage.getItem("userIdpName");
  };

  static removeUserId = () => {
    onmoStorage.removeItem("userId");
    onmoStorage.removeItem("userIdpId");
    onmoStorage.removeItem("userIdpName");
  };

  static setUserRank = (userRank: number, config?: TierLevel[]) => {
    const myRank = getCurrentRank(userRank, config);
    onmoStorage.setItem("userRank", myRank.name);
  };

  static getUserRank = () => {
    return onmoStorage.getItem("userRank") || "";
  };

  static removeUserRank = () => {
    onmoStorage.removeItem("userRank");
  };

  static setPathGameSession = () => {
    onmoStorage.setItem("pathGameSession", window.location.href);
  };

  static getPathGameSession = () => {
    return onmoStorage.getItem("pathGameSession");
  };

  static removePathGameSession = () => {
    onmoStorage.removeItem("pathGameSession");
  };

  static setHideSplashScreen = () => {
    onmoStorage.setItem("hideSplashScreen", "true");
  };

  static setShareToMethod = (method: string) => {
    return onmoStorage.setItem("shareTo", method);
  };

  static getShareToMethod = () => {
    return onmoStorage.getItem("shareTo");
  };

  static getInviteCode = () => {
    return onmoStorage.getItem("inviteCode");
  };

  static removeInviteCode = () => {
    return onmoStorage.removeItem("inviteCode");
  };

  static setGameVolume = (volume: string) => {
    onmoStorage.setItem("gameVolume", volume);
  };

  static getGameVolume = () => {
    return onmoStorage.getItem("gameVolume");
  };

  static getHtmlKeys = () => {
    return onmoStorage.getItem("htmlgame:keys");
  };

  static setHtmlKeys = (keys: string) => {
    onmoStorage.setItem("htmlgame:keys", keys);
  };

  static getTheme = () => {
    return onmoStorage.getItem("theme");
  };

  static setTheme = (theme: string) => {
    onmoStorage.setItem("theme", theme);
  };

  static setPrevPath = (prevPath: string) => {
    return onmoStorage.setItem("prevPath", prevPath);
  };

  static getPrevPath = () => {
    return onmoStorage.getItem("prevPath");
  };

  static removePrevPath = () => {
    return onmoStorage.removeItem("prevPath");
  };

  static setIsUseCookie = (isUseCookie: string) => {
    onmoStorage.setItem("is-use-cookie", isUseCookie);
  };

  static getIsUseCookie = () => {
    return onmoStorage.getItem("is-use-cookie");
  };

  static setTimeClaimCoin = (userId: string, timeStart?: number) => {
    return localStorage.setItem(
      `timeClaimCoin-${userId}`,
      timeStart?.toString() || Date.now().toString(),
    );
  };

  static getTimeClaimCoin = (userId: string) => {
    return localStorage.getItem(`timeClaimCoin-${userId}`);
  };

  static removeTimeClaimCoin = (userId: string) => {
    return localStorage.removeItem(`timeClaimCoin-${userId}`);
  };

  static setAppVersion = () => {
    return onmoStorage.setItem(
      "appVersion",
      import.meta.env.REACT_APP_BUILD || "",
    );
  };

  static setLoginType = (type: string) => {
    return onmoStorage.setItem("loginType", type);
  };

  static getLoginType = () => {
    return onmoStorage.getItem("loginType") || "";
  };

  static removeLoginType = () => {
    return onmoStorage.removeItem("loginType");
  };

  static setSearchHistory = (query: string, type: string) => {
    const searchHistory = this.getSearchHistory(type);
    const newSearchHistory = uniq([query].concat(searchHistory)).slice(0, 30);
    return onmoStorage.setItem(
      type === "game" ? "gameSearchHistory" : "friendSearchHistory",
      JSON.stringify(newSearchHistory),
    );
  };

  static getSearchHistory = (type: string) => {
    return JSON.parse(
      onmoStorage.getItem(
        type === "game" ? "gameSearchHistory" : "friendSearchHistory",
      ) || "[]",
    ) as string[];
  };

  static removeSearchHistory = () => {
    localStorage.removeItem("friendSearchHistory");
    localStorage.removeItem("gameSearchHistory");
  };

  static setDeepLink = (link?: string | undefined) => {
    if (link) {
      sessionStorage.setItem("deepLink", link);
      if (link.includes("/game/")) {
        const gameId = Auth.getGameIdFromOnCode(link);
        onmoStorage.setItem("gameIdOnCode", gameId);
      }
      return;
    }
    const queryParams = new URL(window.location.href);

    // Remove oncode or state from query params because we don't want to save it in deep link
    queryParams.searchParams.delete("oncode");
    queryParams.searchParams.delete("state");
    const deepLink = queryParams.href;

    const pageHasDeepLinks = [
      PageRoutes.GameDetail,
      PageRoutes.UserProfile,
      PageRoutes.PlayBattle,
      PageRoutes.PlayTournament,
      PageRoutes.LiveStream,
      PageRoutes.PlayChallenge,
    ];

    // need loginType to check is logged or not
    const loginType = this.getLoginType();
    const isSaveDeepLink =
      deepLink &&
      !loginType &&
      pageHasDeepLinks.find((page) => deepLink?.includes(page));

    if (isSaveDeepLink) sessionStorage.setItem("deepLink", deepLink);
  };

  static getDeepLink = () => {
    return sessionStorage.getItem("deepLink");
  };

  static removeDeepLink = () => {
    sessionStorage.removeItem("deepLink");
  };

  static setShareParams = (params: string) => {
    onmoStorage.setItem("shareParams", params);
  };

  static getShareParams = () => {
    const battleShareParams = onmoStorage.getItem("shareParams");
    return battleShareParams ? JSON.parse(battleShareParams) : null;
  };

  static removeShareParams = () => {
    onmoStorage.removeItem("shareParams");
  };

  static setOncode = (oncode: string) => {
    sessionStorage.setItem("oncode", oncode);
  };

  static getOncode = () => {
    return sessionStorage.getItem("oncode") || undefined;
  };

  static removeOncode = () => {
    sessionStorage.removeItem("oncode");
  };

  static setState = (state: string) => {
    sessionStorage.setItem("state", state);
  };

  static getState = () => {
    return sessionStorage.getItem("state") || undefined;
  };

  static removeState = () => {
    sessionStorage.removeItem("state");
  };

  static setHidePwaBanner = () => {
    return onmoStorage.setItem("hidePwaBanner", "true");
  };

  static isHidePwaBanner = () => {
    return (
      !!onmoStorage.getItem("hidePwaBanner") ||
      !isMobile ||
      System.isPwaInstalled()
    );
  };

  static setListTournament = (tournamentList: string) => {
    onmoStorage.setItem("listTournament", tournamentList);
  };

  static getListTournament = () => {
    return JSON.parse(onmoStorage.getItem("listTournament") || "null");
  };

  static setConFig = (config: string) => {
    onmoStorage.setItem("config", config);
  };

  static getConFig = () => {
    return JSON.parse(onmoStorage.getItem("config") || "null");
  };

  static setListGames = (listGames: string) => {
    onmoStorage.setItem("listGames", listGames);
  };

  static getListGames = () => {
    return JSON.parse(onmoStorage.getItem("listGames") || "null");
  };

  static setGamesForYou = (gamesForYou: string) => {
    onmoStorage.setItem("gamesForYou", gamesForYou);
  };

  static getGamesForYou = () => {
    return JSON.parse(onmoStorage.getItem("gamesForYou") || "null");
  };

  static setListLiveStreams = (listLiveStreams: string) => {
    onmoStorage.setItem("listLiveStreams", listLiveStreams);
  };

  static getListLiveStreams = () => {
    return JSON.parse(onmoStorage.getItem("listLiveStreams") || "[]");
  };

  static setShareOncode = (shareOncode: string) => {
    onmoStorage.setItem("shareOncode", shareOncode);
  };

  static getShareOncode = () => {
    try {
      return JSON.parse(onmoStorage.getItem("shareOncode") || "{}");
    } catch (error) {
      return {};
    }
  };

  static removeShareOncode = () => {
    onmoStorage.removeItem("shareOncode");
  };

  static getHideFullScreen = () => {
    return onmoStorage.getItem("isHideFullscreen") === "true";
  };

  static isShowedUnsupportedDevice = () => {
    return !!onmoStorage.getItem("isShowedUnsupportedDevice");
  };

  static setShowedUnsupportedDevice = () => {
    onmoStorage.setItem("isShowedUnsupportedDevice", "true");
  };

  static setHidePwaEndscreen = () => {
    onmoStorage.setItem("hidePwaEndscreen", "true");
  };

  static removeHidePwaEndscreen = () => {
    onmoStorage.removeItem("hidePwaEndscreen");
  };

  static getHidePwaEndscreen = () => {
    return (
      onmoStorage.getItem("hidePwaEndscreen") === "true" &&
      !this.isHidePwaBanner()
    );
  };

  static setLastDayShowPwa = () => {
    onmoStorage.setItem(
      "lastDayShowPwa",
      new Date().toLocaleString().split(",")[0],
    );
  };

  static getLastDayShowPwa = () => {
    return onmoStorage.getItem("lastDayShowPwa");
  };

  static setTimeOpenFriendsPage = () => {
    onmoStorage.setItem("lastTimeOpenFriendsPage", new Date().toLocaleString());
  };

  static removeTimeOpenFriendsPage = () => {
    onmoStorage.removeItem("lastTimeOpenFriendsPage");
  };

  static notiOpenFriendsPage = () => {
    const lastTimeOpenFriendsPage = onmoStorage.getItem(
      "lastTimeOpenFriendsPage",
    );
    if (!lastTimeOpenFriendsPage) return true;

    const dayOpenFriendsPage = Time.countDayAgo(lastTimeOpenFriendsPage);

    return dayOpenFriendsPage > 0;
  };

  static setRegion = (region: string) => {
    onmoStorage.setItem("region", region);
  };

  static getRegion = () => {
    return JSON.parse(onmoStorage.getItem("region") || "null");
  };

  static getChangeLogVersion = () => {
    return onmoStorage.getItem("changeLogVersion");
  };

  static setChangeLogVersion = (changeLogVersion: string) => {
    onmoStorage.setItem("changeLogVersion", changeLogVersion);
  };

  static setGameOnboardingFlow = (gameOnboardingFlow: string) => {
    onmoStorage.setItem("gameOnboardingFlow", gameOnboardingFlow);
  };

  static getGameOnboardingFlow = () => {
    return onmoStorage.getItem("gameOnboardingFlow") ?? "";
  };

  static setGameIdFromOnCode = (gameId: string) => {
    onmoStorage.setItem("gameIdOnCode", gameId);
  };

  static getGameIdFromOnCode = () => {
    return onmoStorage.getItem("gameIdOnCode");
  };

  static removeGameIdFromOnCode = () => {
    onmoStorage.removeItem("gameIdOnCode");
  };

  static setSessionPlayedToRating = (sessionPlayedToRating: number) => {
    onmoStorage.setItem(
      "sessionPlayedToRating",
      sessionPlayedToRating.toString(),
    );
  };

  static getSessionPlayedToRating = () => {
    return Number(onmoStorage.getItem("sessionPlayedToRating")) || 0;
  };

  static removeSessionPlayedToRating = () => {
    onmoStorage.removeItem("sessionPlayedToRating");
  };

  static getIsLogin = () => {
    return onmoStorage.getItem("isLogin");
  };

  static removeIsLogin = () => {
    onmoStorage.removeItem("isLogin");
  };

  static setIsLogin = () => {
    onmoStorage.setItem("isLogin", "true");
  };
}
