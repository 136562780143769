import { FC, HTMLAttributes, InputHTMLAttributes, useState } from "react";
import clsx from "classnames";
import { StaticImage } from "../../common/StaticImage";
import CloseIcon from "./assets/close.webp";
import CloseIcon2x from "./assets/close@2x.webp";
import CloseIcon3x from "./assets/close@3x.webp";
import { useStyles } from "./TextField.style";
import {
  Typography,
  TypographyColor,
  TypographyVariant,
} from "../Typography/Typography";

export interface ITextFieldProps {
  label?: string;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  errorMessage?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  isShowClear?: boolean;
  maxLength?: number;
  isError?: boolean;
  onChangeValue?: (value: string) => void;
  endAdornment?: React.ReactNode;
}

export const TextField: FC<
  ITextFieldProps & HTMLAttributes<HTMLDivElement>
> = ({
  label,
  fullWidth,
  startIcon,
  errorMessage,
  isShowClear,
  inputProps,
  maxLength,
  isError,
  onChangeValue,
  endAdornment,
  ...otherProps
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<string | number | readonly string[]>(
    inputProps?.value || "",
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      maxLength &&
      value?.toString()?.length >= maxLength &&
      (event.nativeEvent as InputEvent).inputType !== "deleteContentBackward"
    )
      return;
    onChangeValue && onChangeValue(event.target.value);
    setValue(event.target.value);
  };

  const onClear = () => {
    setValue("");
    onChangeValue && onChangeValue("");
  };

  return (
    <div {...otherProps} className={clsx(classes.root, otherProps.className)}>
      {label && (
        <Typography
          variant={TypographyVariant.BodyB5Regular}
          color={TypographyColor.DarkTiertiary}
          data-testid="label-input"
        >
          {label}
        </Typography>
      )}
      <div className={clsx(classes.content, !fullWidth && classes.fitContent)}>
        {startIcon && <div className={classes.startAdornment}>{startIcon}</div>}
        <input
          {...inputProps}
          className={clsx(
            classes.input,
            inputProps?.className,
            fullWidth && classes.fullWidth,
            startIcon && classes.hasStartIcon,
            isShowClear && classes.hasClearIcon,
            isError && classes.errorBorder,
            inputProps?.disabled && classes.textDisable,
          )}
          placeholder={inputProps?.placeholder}
          value={value}
          onChange={onChange}
          disabled={inputProps?.disabled}
        />
        {isShowClear && value && (
          <StaticImage
            src={CloseIcon}
            src2x={CloseIcon2x}
            src3x={CloseIcon3x}
            className={classes.closeIcon}
            onClick={onClear}
            data-testid="clear-icon"
          />
        )}
        {endAdornment && (
          <div className={classes.endAdornment}>{endAdornment}</div>
        )}
      </div>
      {errorMessage && (
        <Typography
          variant={TypographyVariant.BodyB5Regular}
          color={
            isError ? TypographyColor.DarkError : TypographyColor.DarkTiertiary
          }
          data-testid="error-text"
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextField.defaultProps = {
  label: "",
  fullWidth: false,
  errorMessage: "",
  isShowClear: false,
};
