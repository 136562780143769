import { IHistoryTransactionGroup } from "../types/historyTransaction";
import dateFormat from "dateformat";
import { map, groupBy, flattenDeep, sortBy } from "lodash-es";
import { Transaction } from "../graphql/graphql";

export class HistoryTransactionFactory {
  static groupByDateTransactions = (transactions: Transaction[]) => {
    const sortedTransactions = sortBy(transactions, (t) => {
      return new Date(t.date);
    }).reverse();

    const formatTransactionsDate = map(sortedTransactions, (c) => {
      const now = new Date().getTime();
      const timeGap = (now - new Date(c.date).getTime()) / 86400000;
      const todayDate = new Date(now).getDate();
      const createdDate = new Date(c.date).getDate();
      if (timeGap < 1 && todayDate === createdDate) {
        return { ...c, timeline: "Today" };
      }
      const specificDate = dateFormat(c.date, "mmm d, yyyy");
      return { ...c, timeline: specificDate };
    });

    const completedListGroupByCreatedDate = groupBy(
      formatTransactionsDate,
      (o) => o.timeline,
    );

    return completedListGroupByCreatedDate;
  };

  static getCountTransaction = (transactions?: IHistoryTransactionGroup) => {
    return (
      transactions?.pending?.length ||
      0 + flattenDeep(map(Object.values(transactions || {})))?.length
    );
  };

  static findIndexTransaction = (
    id: string,
    transactions?: IHistoryTransactionGroup,
  ) => {
    if (!transactions) return 0;
    return flattenDeep(map(Object.values(transactions))).findIndex(
      (transaction) => transaction.id === id,
    );
  };
}
