import React, { FC, HTMLAttributes } from "react";
import clsx from "classnames";
import { useStyles } from "./Typography.style";

export interface ITypographyProps {
  variant?: TypographyVariant;
  color?: TypographyColor;
  inline?: boolean;
  lines?: 0 | 1 | 2 | 3;
  textAlign?: "center" | "left" | "right" | "justify";
}

const typographyMapping: { [key in TypographyVariant]: string } = {
  // Heading
  "heading-h1-bold": "h1",
  "heading-h2-bold": "h2",
  "heading-h3-bold": "h3",
  "heading-h4-bold": "h4",
  "heading-h5-bold": "h5",
  "heading-h6-bold": "h6",

  // Subtitle s1
  "subtitle-s1-regular": "h5",
  "subtitle-s1-medium": "h5",
  "subtitle-s1-bold": "h5",

  // Subtitle s2
  "subtitle-s2-regular": "h5",
  "subtitle-s2-medium": "h5",
  "subtitle-s2-bold": "h5",

  // Subtitle s3
  "subtitle-s3-regular": "h6",
  "subtitle-s3-medium": "h6",
  "subtitle-s3-bold": "h6",

  // Body b1
  "body-b1-bold": "p",
  "body-b1-regular": "p",
  "body-b1-light": "p",

  // Body b2
  "body-b2-bold": "p",
  "body-b2-regular": "p",
  "body-b2-light": "p",

  // Body b3
  "body-b3-bold": "p",
  "body-b3-regular": "p",
  "body-b3-light": "p",

  // Body b4
  "body-b4-bold": "p",
  "body-b4-regular": "p",
  "body-b4-light": "p",

  // Body b5
  "body-b5-bold": "p",
  "body-b5-regular": "p",
  "body-b5-light": "p",

  // Body b6
  "body-b6-bold": "p",
  "body-b6-regular": "p",
  "body-b6-light": "p",

  // Caption
  "caption-regular": "p",
  "caption-medium": "p",
  "caption-bold": "p",

  // Caption
  "overline-o1-regular": "p",
  "overline-o1-bold": "p",
  "overline-o2-regular": "p",
  "overline-o2-bold": "p",

  // Emphatic
  "emphatic-e1-regular": "h1",
  "emphatic-e1-medium": "h1",
  "emphatic-e1-bold": "h1",
};

export enum TypographyColor {
  Primary = "primary",
  Secondary = "secondary",
  Inherit = "inherit",

  // Text on Dark
  DarkDefault = "dark-default",
  DarkSecondary = "dark-secondary",
  DarkTiertiary = "dark-tiertiary",
  DarkQuinary = "dark-quinary",
  DarkSenary = "dark-senary",
  DarkRelative = "dark-relative",
  DarkError = "dark-error",
  DarkSuccess = "dark-success",
  DarkWarning = "dark-warning",
  DarkInformation = "dark-information",
  DarkDisabled = "dark-disabled",
  DarkCoins = "dark-coins",
  DarkGems = "dark-gems",
}

export enum TypographyVariant {
  // Heading h1
  /**
    @property font-size: 48px; 
    @property line-height: 60px;
    @property font-weight: 700;
    @property font-family: AlumniSans;
  // */
  HeadingH1Bold = "heading-h1-bold",

  // Heading h2
  /**
    @property font-size: 38px;
    @property line-height: 46px;
    @property font-weight: 700;
    @property font-family: AlumniSans;
  */
  HeadingH2Bold = "heading-h2-bold",

  // Heading h3
  /**
    @property font-size: 32px;
    @property line-height: 38px;
    @property font-weight: 700;
    @property font-family: AlumniSans;
  */
  HeadingH3Bold = "heading-h3-bold",

  // Heading h4
  /**
    @property font-size: 28px;
    @property line-height: 34px;
    @property font-weight: 700;
    @property font-family: AlumniSans;
  */
  HeadingH4Bold = "heading-h4-bold",

  // Heading h5
  /**
    @property font-size: 24px;
    @property line-height: 30px;
    @property font-weight: 700;
    @property font-family: AlumniSans;
  */
  HeadingH5Bold = "heading-h5-bold",

  // Heading h6
  /**
    @property font-size: 20px;
    @property line-height: 24px;
    @property font-weight: 700;
    @property font-family: AlumniSans;
  */
  HeadingH6Bold = "heading-h6-bold",

  // Subtitle s1
  /**
    @property font-size: 20px; 
    @property line-height: 28px;
    @property font-weight: 400;
  */
  SubtitleS1Regular = "subtitle-s1-regular",
  /**
    @property font-size: 20px; 
    @property line-height: 28px;
    @property font-weight: 500;
  */
  SubtitleS1Medium = "subtitle-s1-medium",
  /**
    @property font-size: 20px; 
    @property line-height: 28px;
    @property font-weight: 700;
  */
  SubtitleS1Bold = "subtitle-s1-bold",

  // Subtitle s2
  /**
    @property font-size: 18px; 
    @property line-height: 24px;
    @property font-weight: 400;
  */
  SubtitleS2Regular = "subtitle-s2-regular",
  /**
    @property font-size: 18px; 
    @property line-height: 24px;
    @property font-weight: 500;
  */
  SubtitleS2Medium = "subtitle-s2-medium",
  /**
    @property font-size: 18px; 
    @property line-height: 24px;
    @property font-weight: 700;
  */
  SubtitleS2Bold = "subtitle-s2-bold",

  // Subtitle s3
  /**
    @property font-size: 16px; 
    @property line-height: 24px;
    @property font-weight: 400;
  */
  SubtitleS3Regular = "subtitle-s3-regular",
  /**
    @property font-size: 16px; 
    @property line-height: 24px;
    @property font-weight: 500;
  */
  SubtitleS3Medium = "subtitle-s3-medium",
  /**
    @property font-size: 16px; 
    @property line-height: 24px;
    @property font-weight: 700;
  */
  SubtitleS3Bold = "subtitle-s3-bold",

  // Body b1
  /**
    @property font-size: 24px; 
    @property line-height: 28px;
    @property font-weight: 700;
    @property font-family: Roboto;
  */
  BodyB1Bold = "body-b1-bold",
  /**
    @property font-size: 24px; 
    @property line-height: 28px;
    @property font-weight: 400;
    @property font-family: Roboto;
  */
  BodyB1Regular = "body-b1-regular",
  /**
    @property font-size: 24px; 
    @property line-height: 28px;
    @property font-weight: 300;
    @property font-family: Roboto;
  */
  BodyB1Medium = "body-b1-light",

  // Body b2
  /**
    @property font-size: 20px; 
    @property line-height: 24px;
    @property font-weight: 700;
    @property font-family: Roboto;
  */
  BodyB2Bold = "body-b2-bold",
  /**
    @property font-size: 20px; 
    @property line-height: 24px;
    @property font-weight: 400;
    @property font-family: Roboto;
  */
  BodyB2Regular = "body-b2-regular",
  /**
    @property font-size: 20px; 
    @property line-height: 24px;
    @property font-weight: 300;
    @property font-family: Roboto;
  */
  BodyB2Medium = "body-b2-light",

  // Body b3
  /**
    @property font-size: 18px; 
    @property line-height: 22px;
    @property font-weight: 700;
    @property font-family: Roboto;
  */
  BodyB3Bold = "body-b3-bold",
  /**
    @property font-size: 18px; 
    @property line-height: 22px;
    @property font-weight: 400;
    @property font-family: Roboto;
  */
  BodyB3Regular = "body-b3-regular",
  /**
    @property font-size: 18px; 
    @property line-height: 22px;
    @property font-weight: 300;
    @property font-family: Roboto;
  */
  BodyB3Medium = "body-b3-light",

  // Body b4
  /**
    @property font-size: 16px; 
    @property line-height: 24px;
    @property font-weight: 700;
    @property font-family: Roboto;
  */
  BodyB4Bold = "body-b4-bold",
  /**
    @property font-size: 16px; 
    @property line-height: 24px;
    @property font-weight: 400;
    @property font-family: Roboto;
  */
  BodyB4Regular = "body-b4-regular",
  /**
    @property font-size: 16px; 
    @property line-height: 24px;
    @property font-weight: 300;
    @property font-family: Roboto;
  */
  BodyB4Medium = "body-b4-light",

  // Body b5
  /**
    @property font-size: 14px; 
    @property line-height: 20px;
    @property font-weight: 700;
    @property font-family: Roboto;
  */
  BodyB5Bold = "body-b5-bold",
  /**
    @property font-size: 14px; 
    @property line-height: 20px;
    @property font-weight: 400;
    @property font-family: Roboto;
  */
  BodyB5Regular = "body-b5-regular",
  /**
    @property font-size: 14px; 
    @property line-height: 20px;
    @property font-weight: 300;    
    @property font-family: Roboto;
  */
  BodyB5Medium = "body-b5-light",

  // Body b6
  /**
    @property font-size: 12px; 
    @property line-height: 16px;
    @property font-weight: 700;
    @property font-family: Roboto;
  */
  BodyB6Bold = "body-b6-bold",
  /**
    @property font-size: 12px; 
    @property line-height: 16px;
    @property font-weight: 400;
    @property font-family: Roboto;
  */
  BodyB6Regular = "body-b6-regular",
  /**
    @property font-size: 12px; 
    @property line-height: 16px;
    @property font-weight: 300;    
    @property font-family: Roboto;
  */
  BodyB6Medium = "body-b6-light",

  // Caption
  /**
    @property font-size: 12px; 
    @property line-height: 16px;
    @property font-weight: 400;
    @property font-family: Roboto;
  */
  CaptionRegular = "caption-regular",
  /**
    @property font-size: 12px; 
    @property line-height: 18px;
    @property font-weight: 500;
  */
  CaptionMedium = "caption-medium",
  /**
    @property font-size: 12px; 
    @property line-height: 18px;
    @property font-weight: 700;
  */
  CaptionBold = "caption-bold",

  // Caption
  /**
    @property font-size: 14px; 
    @property line-height: 20px;
    @property font-weight: 400;
  */
  OverlineO1Regular = "overline-o1-regular",
  /**
    @property font-size: 14px; 
    @property line-height: 20px;
    @property font-weight: 500;
  */
  OverlineO1Bold = "overline-o1-bold",
  /**
    @property font-size: 12px; 
    @property line-height: 20px;
    @property font-weight: 400;
  */
  OverlineO2Regular = "overline-o2-regular",
  /**
    @property font-size: 12px; 
    @property line-height: 20px;
    @property font-weight: 700;
  */
  OverlineO2Bold = "overline-o2-bold",
  /**
    @property font-size: 40px; 
    @property line-height: 48px;
    @property font-weight: 400;
  */
  EmphaticE1Regular = "emphatic-e1-regular",
  /**
   @property font-size: 40px; 
    @property line-height: 48px;
    @property font-weight: 500;
  */
  EmphaticE1Medium = "emphatic-e1-medium",
  /**
    @property font-size: 40px; 
    @property line-height: 48px;
    @property font-weight: 700;
  */
  EmphaticE1Bold = "emphatic-e1-bold",
}

export const Typography: FC<
  ITypographyProps & HTMLAttributes<HTMLParagraphElement | HTMLTitleElement>
> = ({
  children,
  textAlign,
  variant,
  color,
  inline,
  lines,
  style,
  ...otherProps
}) => {
  const classes = useStyles();
  return React.createElement(
    inline
      ? "span"
      : typographyMapping[variant || TypographyVariant.BodyB1Regular],
    {
      ...otherProps,
      style: {
        ...(textAlign ? { textAlign } : undefined),
        display: inline ? "inline-block" : undefined,
        ...style,
      },
      className: clsx(classes.root, variant, color, otherProps.className, {
        [`lines-${lines}`]: !!lines,
      }),
    },
    children,
  );
};

Typography.defaultProps = {
  variant: TypographyVariant.BodyB1Regular,
  inline: false,
};
