import React, { HTMLAttributes, FC, useState } from "react";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "../../core";
import { useStyles } from "./AccountBlocked.style";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { HelpShift } from "../../common/HelpShift";
import {
  TypographyColor,
  TypographyVariant,
} from "../../core/Typography/Typography";
import { logoutKeycloak } from "../../../slices/keycloak";

interface IAccountBlockedProps {
  onBack?: () => void;
}

export const AccountBlocked: FC<
  IAccountBlockedProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state) => state.theme);

  const { t } = useTranslation();
  const { onBack, className, ...otherProps } = props;
  const [isOpenHelpShift, setIsOpenHelpShift] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onContactSupport = () => {
    setIsOpenHelpShift(true);
  };

  const onBackLogin = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      dispatch(logoutKeycloak());
      onBack?.();
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  return (
    <Box {...otherProps} className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <Typography
          variant={TypographyVariant.HeadingH5Bold}
          color={TypographyColor.DarkDefault}
        >
          {t("accBlock_Account Disabled", { defaultValue: "Account disabled" })}
        </Typography>
        <Typography
          variant={TypographyVariant.BodyB5Regular}
          color={TypographyColor.DarkSecondary}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t(`accBlock_Account Disabled description`, {
                defaultValue:
                  "Your account has been disabled. If you believe it was done by mistake please contact {{supportLink}} stating your authentication method and email or phone number.",
                supportLink: `<a style="color:${theme?.theme?.palette?.secondary?.main}">support@onmo.com</a>`,
              }),
            }}
          />
        </Typography>
        <Button
          color="primary"
          size="small"
          className={classes.btnContact}
          onClick={onContactSupport}
        >
          {t("accBlock_Contact Support", {
            defaultValue: "Contact Support",
          })}
        </Button>
        <Typography
          variant={TypographyVariant.BodyB5Bold}
          color={TypographyColor.DarkDefault}
          onClick={onBackLogin}
        >
          {t("accBlock_Back to Login", {
            defaultValue: "Back to Login",
          })}
        </Typography>
      </div>
      {isOpenHelpShift && <HelpShift onClose={setIsOpenHelpShift} />}
    </Box>
  );
};
