import {
  createSelector,
  createSlice,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import { map, filter } from "lodash-es";
import { AppDispatch, RootState } from "../store";
import { TABS } from "../constants/shopWallet";
import { HistoryTransactionFactory } from "../models/historyTransactionFactory";
import { LIMIT_LOAD_TRANSACTION } from "../constants/historyTransaction";
import { setNotificationError } from "./alert";
import { TFunction } from "react-i18next";
import { queryUserTransactions } from "../graphql/resolvers/queries/users";
import { CoinType, Transaction, TransactionType } from "../graphql/graphql";

interface ITransactionHistorySlice {
  listTransactionHistory: null | Transaction[];
  activeCategory: null | string;
  searchQuery: string;
  offset: number;
  isLoadedAll: boolean;
  isLoading: boolean;
}

const initialState = {
  listTransactionHistory: null,
  activeCategory: TABS[0].id,
  searchQuery: "",
  offset: 0,
  isLoadedAll: false,
  isLoading: false,
} as ITransactionHistorySlice;

export const resetTransactionHistoryState = createAction(
  "transactionHistory/resetState",
);

export const transactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState: initialState,
  reducers: {
    setActiveCategory: (state, action) => {
      const { activeCategory } = action.payload;
      state.activeCategory = activeCategory;
    },
    setSearchQuery: (state, action) => {
      const { query } = action.payload;
      state.searchQuery = query;
    },
    resetTransaction: (state) => {
      state.offset = 0;
      state.listTransactionHistory = null;
      state.isLoadedAll = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListTransactionHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchListTransactionHistory.fulfilled, (state, action) => {
        const offset = action.meta.arg.offset || 0;
        const items = action.payload;
        if (offset > 0 && state.offset >= offset + items.length) {
          return;
        }
        if (items) {
          state.listTransactionHistory =
            offset === 0
              ? items
              : [...(state.listTransactionHistory || []), ...items];
        }
        state.isLoadedAll = items.length < LIMIT_LOAD_TRANSACTION;
        state.offset = offset + items.length;
        state.isLoading = false;
      })
      .addCase(fetchListTransactionHistory.rejected, (state, action) => {
        state.isLoading = false;
        console.error(action.error);
      })
      .addCase(resetTransactionHistoryState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { setActiveCategory, setSearchQuery, resetTransaction } =
  transactionHistorySlice.actions;

export const fetchListTransactionHistory = createAsyncThunk(
  "transactionHistory/fetchListTransactionHistory",
  async (
    params: { offset: number; coinTypes: CoinType[]; userId: string },
    { dispatch },
  ) => {
    try {
      const { offset, userId, coinTypes } = params;
      const { transactions } = await queryUserTransactions(
        {
          userId,
          limit: LIMIT_LOAD_TRANSACTION,
          offset,
          coinTypes,
        },
        { fetchPolicy: "network-only" },
      );
      return map(transactions.items, (transaction) => transaction);
    } catch (e) {
      if (e instanceof Error) {
        (dispatch as AppDispatch)(setNotificationError(e.message));
      }
      throw e;
    }
  },
);

const listTransactionHistory = (state: RootState) =>
  state.transactionHistory.listTransactionHistory;
const activeCategory = (state: RootState) =>
  state.transactionHistory.activeCategory;
const searchQuery = (state: RootState) => state.transactionHistory.searchQuery;

export const filterTransactions = createSelector(
  [listTransactionHistory, activeCategory],
  (listTransactionHistory, activeCategory) => {
    if (!listTransactionHistory || !activeCategory) return;
    const isAllCategories = activeCategory === TABS[0].id;
    const transactionFilter = filter(listTransactionHistory, (t) => {
      return activeCategory?.split(",")?.includes(t.type);
    });

    return HistoryTransactionFactory.groupByDateTransactions(
      isAllCategories ? listTransactionHistory : transactionFilter,
    );
  },
);

export const searchTransactions = createSelector(
  [listTransactionHistory, searchQuery],
  (listTransactionHistory, searchQuery) => {
    if (!listTransactionHistory) return;
    const transactionFilter = filter(listTransactionHistory, (t) => {
      return t?.game?.title?.toLowerCase()?.includes(searchQuery.toLowerCase());
    });
    if (transactionFilter?.length === 0) return;

    return HistoryTransactionFactory.groupByDateTransactions(transactionFilter);
  },
);

export const selectTitleTransactionActionType = (
  historyTransaction: Transaction,
) =>
  createSelector(
    (state: RootState) => state,
    () => {
      const { game, referenceId, type } = historyTransaction;
      if (game?.id) return game?.title;
      if (type === TransactionType.Topup) return "common_Top-up Coins";
      if (type === TransactionType.LoginReward) return "common_Reward";
      if (type === TransactionType.Subscription) return "common_Subscription";
      if (type === TransactionType.Expired) return "battle_Expired";
      if (
        referenceId?.includes("bonus") ||
        type === TransactionType.Bonus ||
        type === TransactionType.Free ||
        type === TransactionType.Welcome ||
        type === TransactionType.Manual
      )
        return "common_Bonus Coins";
      return "...";
    },
  );

export const selectStatusTransaction = (
  historyTransaction: Transaction,
  t: TFunction<"translation", undefined>,
  expireDateOn?: string,
) =>
  createSelector(
    (state: RootState) => state,
    () => {
      const { type } = historyTransaction;

      if (type === TransactionType.Battle) return t("history_Battle Fee");
      if (type === TransactionType.Tournament)
        return t("history_Tournament Fee");
      if (type === TransactionType.Challenge) return t("history_Challenge Fee");
      return expireDateOn;
    },
  );

export const selectIsPlusCoinCash = (amount: number) =>
  createSelector(
    (state: RootState) => state,
    () => {
      return amount > 0;
    },
  );

export const selectGetLocaleDate = (date: string) =>
  createSelector(
    (state: RootState) => state,
    () => {
      const newDate = new Date(date);
      const formattedDate = newDate.toLocaleDateString();

      return formattedDate;
    },
  );

export default transactionHistorySlice.reducer;
