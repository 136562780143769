import {
  GameSessionV2,
  LivestreamSession,
  PublicUser,
} from "../../legacyGraphql/graphql";
import { startLivestream } from "../../legacyGraphql/resolvers/mutation/gameSessions";
import { LiveStreamEvents } from "../../constants/livestream";
import {
  ILiveStreamMessage,
  ILiveStreamMessageReceived,
} from "../../types/liveStream";
import { LiveStreamWS } from "../liveStream";
import { System } from "../system";

export const handleLiveStreamMessage = (
  message: ILiveStreamMessageReceived,
): ILiveStreamMessage => {
  const { event, data } = message;
  const { sessionId } = data;

  switch (event) {
    case LiveStreamEvents.error:
      return {
        error: true,
        sessionId,
        code: data.code,
        message: data.message,
      };
    case LiveStreamEvents.playerDisconnected:
    case LiveStreamEvents.viewers:
    case LiveStreamEvents.sessionEnded:
    case LiveStreamEvents.sessionJoined:
    case LiveStreamEvents.sessionCreated:
      return { type: event, sessionId, data };
    case LiveStreamEvents.emoji:
      return { type: event, sessionId, emoji: data.emoji, sender: data.sender };
    case LiveStreamEvents.battleEnded:
    case LiveStreamEvents.newBattleStarted:
    case LiveStreamEvents.battleReady:
      return { type: event, sessionId, data };
    case LiveStreamEvents.controlRequest:
    case LiveStreamEvents.controlRequestDenied:
    case LiveStreamEvents.controlBackToHost:
    case LiveStreamEvents.controlRequestCancelled:
    case LiveStreamEvents.newPlayer:
      return { type: event, data, sessionId };
    default:
      return { sessionId, data };
  }
};

export const startLiveStreamSession = async (
  gameSession: GameSessionV2,
  wsConnectionId: string | null,
  liveStreamWS: LiveStreamWS,
) => {
  if (!gameSession) return;

  try {
    const livestream = await startLivestream({
      gameSessionId: gameSession.id,
      wsConnectionId: wsConnectionId || "",
    });
    liveStreamWS.startSession({
      sessionId: livestream?.id,
      battleId: livestream?.battleId,
      edgeNodeId: livestream.edgeNodeId,
      gameSessionId: livestream.gameSessionId,
    });

    return livestream;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getDifferenceViewer = (
  viewers: PublicUser[],
  newViewers: PublicUser[],
  viewerControl?: PublicUser,
  host?: PublicUser,
) => {
  const findViewer = (id: string) => {
    return newViewers.find((newViewer) => newViewer.id === id);
  };
  return (
    viewers.filter(
      (viewer) =>
        !findViewer(viewer.id) &&
        viewer.id !== viewerControl?.id &&
        viewer.id !== host?.id,
    ) || []
  );
};

export const mergeListViewer = (
  currentViewers: PublicUser[],
  newViewers: PublicUser[],
  host: PublicUser,
  viewerControl?: PublicUser,
) => {
  const listViewerUpdated = newViewers.map((newViewer) => {
    return (
      currentViewers.find(
        (currentViewer) => currentViewer.id === newViewer.id,
      ) || newViewer
    );
  });

  if (!viewerControl) {
    return listViewerUpdated;
  } else {
    return listViewerUpdated
      .concat(viewerControl)
      .filter((viewer) => viewer.id !== host.id);
  }
};
export const getListCurrentViewer = (livestream: LivestreamSession) => {
  const { viewers, currentPlayer, host } = livestream;

  return viewers
    .concat(currentPlayer)
    .filter((viewer) => viewer.id !== host.id);
};

export const getTimeoutSimulateViewerJoin = () => {
  return System.getRandomNumber(10000, 200000);
};

export const getTimeoutSimulateViewerEmoji = () => {
  return System.getRandomNumber(5000, 30000);
};

export const getTimeoutSimulateViewerLeave = () => {
  return System.getRandomNumber(10000, 300000);
};
