import apollo from "../../../clients/apollo";
import { GlobalLeaderboardType, Query } from "../../graphql";
import { globalLeaderboardsGQL } from "../../queries/globalLeaderboard";

const apolloClient = apollo.getInstance();

export const getGlobalLeaderboards = async (params: {
  globalLeaderboardType?: GlobalLeaderboardType;
  leaderboardId?: string;
}): Promise<Query["leaderboard"]> => {
  const res = await apolloClient.query({
    query: globalLeaderboardsGQL,
    variables: { input: params },
  });

  return res.data.leaderboard;
};
