import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AsyncAppThunk, RootState } from "../store";
import { QueryOptions } from "@apollo/client";
import {
  IUserRelation,
  getNewFollowStatus,
  userFollowSuggestionToUserRelation,
  userFollowToUserRelation,
} from "../models/friends/friends";
import { FollowStatus, UserFriendRequest } from "../legacyGraphql/graphql";
import {
  followUser as followUserMutation,
  unfollowUser as unfollowUserMutation,
} from "../legacyGraphql/resolvers/mutation/friends";
import {
  listFollowSuggestions,
  listFollowers,
  listFollowing,
} from "../legacyGraphql/resolvers/queries/friends";
import { setNotificationError } from "./alert";
import { getUserProfile } from "./user";
import { uniqBy } from "lodash-es";

export const NB_START_ITEM = 15;
export const NB_ITEM_LOAD_MORE = 10;

interface IFriends {
  listFollowers?: IUserRelation[];
  listFollowing?: IUserRelation[];
  listFollowSuggestions?: IUserRelation[];
  newFriendsReceived?: UserFriendRequest;
  totalFollowing: number;
  totalFollowers: number;
  isLimitedFollowing: boolean;
  isLimitedFollower: boolean;
}

const initialState = {
  friends: undefined,
  friendsSent: undefined,
  friendsReceived: undefined,
  newFriendsReceived: undefined,
  totalFollowing: 0,
  totalFollowers: 0,
  isLimitedFollowing: false,
  isLimitedFollower: false,
} as IFriends;

export const resetFriendsState = createAction("friends/resetState");

export const friendsSlice = createSlice({
  name: "friends",
  initialState: initialState,
  reducers: {
    updateFollowerList: (
      state,
      action: PayloadAction<{ listFollowers: IUserRelation[] }>,
    ) => {
      state.listFollowers = action.payload.listFollowers;
    },
    updateFollowingList: (
      state,
      action: PayloadAction<{ listFollowing: IUserRelation[] }>,
    ) => {
      state.listFollowing = action.payload.listFollowing;
    },
    updateFollowSuggestionsList: (
      state,
      action: PayloadAction<{ listFollowSuggestions: IUserRelation[] }>,
    ) => {
      state.listFollowSuggestions = action.payload.listFollowSuggestions;
    },
    updateTotalFollowers: (
      state,
      action: PayloadAction<{ totalFollowers: number }>,
    ) => {
      state.totalFollowers = action.payload.totalFollowers;
    },
    updateTotalFollowing: (
      state,
      action: PayloadAction<{ totalFollowing: number }>,
    ) => {
      state.totalFollowing = action.payload.totalFollowing;
    },
    updateLimitedFollowing: (
      state,
      action: PayloadAction<{ isLimitedFollowing: boolean }>,
    ) => {
      state.isLimitedFollowing = action.payload.isLimitedFollowing;
    },
    updateLimitedFollower: (
      state,
      action: PayloadAction<{ isLimitedFollower: boolean }>,
    ) => {
      state.isLimitedFollower = action.payload.isLimitedFollower;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFriendsState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateFollowerList,
  updateFollowingList,
  updateFollowSuggestionsList,
  updateTotalFollowers,
  updateTotalFollowing,
  updateLimitedFollowing,
  updateLimitedFollower,
} = friendsSlice.actions;

export const fetchListFollowers =
  (option?: Partial<QueryOptions>): AsyncAppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (!state.user.me) return;

    try {
      const listFollowersRes = await listFollowers(option, {
        limit: NB_START_ITEM,
        offset: 0,
      });
      dispatch(
        updateTotalFollowers({ totalFollowers: listFollowersRes.totalCount }),
      );

      const listFollowersRelation = userFollowToUserRelation(
        listFollowersRes.items,
        state.user.me.id,
      );
      dispatch(
        updateLimitedFollower({
          isLimitedFollower: listFollowersRes.items.length < NB_START_ITEM,
        }),
      );
      dispatch(updateFollowerList({ listFollowers: listFollowersRelation }));
    } catch (e) {
      if (!state.friends.listFollowers)
        dispatch(updateFollowerList({ listFollowers: [] }));
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
      console.error(e);
    }
  };

export const fetchListFollowing =
  (option?: Partial<QueryOptions>): AsyncAppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (!state.user.me) return;

    try {
      const listFollowingRes = await listFollowing(option, {
        limit: NB_START_ITEM,
        offset: 0,
      });
      dispatch(
        updateTotalFollowing({ totalFollowing: listFollowingRes.totalCount }),
      );
      dispatch(
        updateLimitedFollowing({
          isLimitedFollowing: listFollowingRes.items.length < NB_START_ITEM,
        }),
      );

      const listFollowingRelation = userFollowToUserRelation(
        listFollowingRes.items,
        state.user.me.id,
      );
      dispatch(
        updateFollowingList({
          listFollowing: uniqBy(listFollowingRelation, "id"),
        }),
      );
    } catch (e) {
      if (!state.friends.listFollowing)
        dispatch(updateFollowingList({ listFollowing: [] }));
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
      console.error(e);
    }
  };
export const fetchNextPageListFollowing =
  (option?: Partial<QueryOptions>): AsyncAppThunk =>
  async (dispatch, getState) => {
    const friendState = getState().friends;

    if (!friendState.listFollowing?.length || friendState.isLimitedFollowing) {
      return;
    }

    try {
      const listFollowingRes = await listFollowing(option, {
        limit: NB_ITEM_LOAD_MORE,
        offset: friendState.listFollowing.length,
      });
      const listFollowingRelation = userFollowToUserRelation(
        listFollowingRes.items,
        getState().user.me?.id,
      );

      dispatch(
        updateLimitedFollowing({
          isLimitedFollowing: listFollowingRes.items.length < NB_ITEM_LOAD_MORE,
        }),
      );
      dispatch(
        updateFollowingList({
          listFollowing: friendState.listFollowing.concat(
            listFollowingRelation,
          ),
        }),
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
      console.error(e);
    }
  };
export const fetchNextPageListFollower =
  (option?: Partial<QueryOptions>): AsyncAppThunk =>
  async (dispatch, getState) => {
    const friendState = getState().friends;

    if (!friendState.listFollowers?.length || friendState.isLimitedFollower)
      return;

    try {
      const listFollowerRes = await listFollowers(option, {
        limit: NB_ITEM_LOAD_MORE,
        offset: friendState.listFollowers.length,
      });
      const listFollowerRelation = userFollowToUserRelation(
        listFollowerRes.items,
        getState().user.me?.id,
      );

      dispatch(
        updateLimitedFollower({
          isLimitedFollower: listFollowerRes.items.length < NB_ITEM_LOAD_MORE,
        }),
      );
      dispatch(
        updateFollowerList({
          listFollowers: friendState.listFollowers.concat(listFollowerRelation),
        }),
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
      console.error(e);
    }
  };

export const fetchListFollowSuggestions =
  (option?: Partial<QueryOptions>): AsyncAppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      const listFollowSuggestionsRes = await listFollowSuggestions(option);
      const listFollowSuggestionsRelation = userFollowSuggestionToUserRelation(
        listFollowSuggestionsRes.items,
      );
      dispatch(
        updateFollowSuggestionsList({
          listFollowSuggestions: listFollowSuggestionsRelation,
        }),
      );
    } catch (e) {
      if (!state.friends.listFollowSuggestions)
        dispatch(updateFollowSuggestionsList({ listFollowSuggestions: [] }));
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
      console.error(e);
    }
  };

export const fetchFriendPage = (): AsyncAppThunk => async (dispatch) => {
  await Promise.all([
    dispatch(fetchListFollowers()),
    dispatch(fetchListFollowing()),
    dispatch(fetchListFollowSuggestions()),
  ]);
};

export const updateFollowStatus =
  (userRelation: IUserRelation): AsyncAppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const newFollowStatus = getNewFollowStatus(userRelation);

    if (state.friends.listFollowing) {
      dispatch(
        updateTotalFollowing({
          totalFollowing: state.friends.totalFollowing + 1,
        }),
      );

      // Update list follow suggestions
      const newListFollowSuggestions = state.friends.listFollowSuggestions?.map(
        (item) => {
          if (item.id === newFollowStatus.id) return newFollowStatus;
          else return item;
        },
      );
      dispatch(
        updateFollowSuggestionsList({
          listFollowSuggestions: newListFollowSuggestions || [],
        }),
      );

      // Update list followers
      const newListFollower = state.friends.listFollowers?.map((item) => {
        if (item.id === newFollowStatus.id) return newFollowStatus;
        else return item;
      });
      dispatch(
        updateFollowerList({
          listFollowers: newListFollower || [],
        }),
      );
    }
  };

export const followUser =
  (userId: string, fetchProfile?: boolean): AsyncAppThunk =>
  async (dispatch) => {
    try {
      await followUserMutation(userId);

      if (fetchProfile) {
        await dispatch(getUserProfile(userId));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const unfollowUser =
  (userId: string, fetchProfile?: boolean): AsyncAppThunk =>
  async (dispatch) => {
    try {
      await unfollowUserMutation(userId);

      if (fetchProfile) {
        await dispatch(getUserProfile(userId));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const selectIsMutual = createSelector(
  [
    (state: RootState) => state.friends.listFollowers,
    (state: RootState, userId: string) => userId,
  ],
  (listFollowers, userId) => {
    // Check if user is mutual, we can check with listFollowing or listFollowers
    const isMutual = listFollowers?.find(
      (item) =>
        item.id === userId &&
        item.followStatus === FollowStatus.FollowingAndFollowedBy,
    );
    return Boolean(isMutual);
  },
);

export default friendsSlice.reducer;
