import gql from "graphql-tag";

export const getUserNotificationsGQL = gql`
  query User($userId: UUID!) {
    user(id: $userId) {
      id
      notifications {
        id
        type
        createdAt
        data {
          ... on AchievementUserNotificationData {
            __typename
            balance
            type
          }
          ... on LeaderboardUserNotificationData {
            timeFrame
            endDate
            leaderboardId
            rank
            score
            leaderboardType
          }
          ... on TournamentUserNotificationData {
            __typename
            rank
            gameId
            tournamentId
          }
          ... on FollowUserNotificationData {
            __typename
            followerUser {
              id
              username
              xp
              avatar
            }
          }
          ... on BattleUserNotificationData {
            host {
              avatar
              id
              username
            }
            opponent {
              avatar
              id
              username
            }
            gameId
            matchId
          }
        }
      }
    }
  }
`;
