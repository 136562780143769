import { gql } from "graphql-tag";

export const getActivitiesGQL = gql`
  query Me($input: ActivitiesInput) {
    me {
      activities(input: $input) {
        total
        items {
          activityType
          createdAt
          data {
            ... on BattleActivityData {
              rank
              winner
              battleId
              battleType
              opponentUsers {
                id
                username
                xp
                avatar
              }
              game {
                id
              }
              moment {
                challengeNumber
              }
            }
            ... on LeaderboardActivityData {
              timeFrame
              endDate
              leaderboardRank
              type
            }
            ... on UserLeaderboardActivityData {
              __typename
              endDate
              leaderboardId
              leaderboardRank
              repeatCycle
              score
            }
          }
          id
        }
      }
    }
  }
`;

export const getTotalActivitiesGQL = gql`
  query Me($input: ActivitiesInput) {
    me {
      activities(input: $input) {
        total
      }
    }
  }
`;
