import apollo from "../../../clients/apollo";
import { ApiLegacyName } from "../../../constants/apollo";
import { Mutation } from "../../../legacyGraphql/graphql";
import {
  playSoloMomentGQL,
  endHtmlGameSessionGQL,
  enterSoloMomentGQL,
  startLivestreamGQL,
  joinLivestreamGQL,
} from "../../mutations/gameSessions";
import {
  IParamsPlayMoment,
  IParamsEndHtmlGameSession,
  IReEnterSoloGameInput,
  IEnterSoloGameInput,
  IStartLivestreamInput,
  IJoinLivestreamInput,
} from "./../../../types/gameSessions";

const apolloClient = apollo.getInstance();

export const playSoloMoment = async (
  playSoloMomentInput: IParamsPlayMoment,
): Promise<Mutation["playSoloMoment"]> => {
  const res = await apolloClient.mutate({
    mutation: playSoloMomentGQL,
    variables: { playSoloMomentInput },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.playSoloMoment;
};

export const endHtmlGameSession = async (
  params: IParamsEndHtmlGameSession,
): Promise<Mutation["endHtmlGameSession"]> => {
  const res = await apolloClient.mutate({
    context: { api: ApiLegacyName.PrimaryLegacy },
    mutation: endHtmlGameSessionGQL,
    variables: {
      endHtmlGameSessionInput: {
        ...params,
        failureMessage: params?.failureMessage || "",
      },
    },
  });

  return res.data.endHtmlGameSession;
};

/**
 * To get device info
 */

export const enterSoloGameMoment = async (
  enterSoloMomentInput: IEnterSoloGameInput | IReEnterSoloGameInput,
): Promise<Mutation["enterSoloMoment"]> => {
  const response = await apolloClient.mutate({
    mutation: enterSoloMomentGQL,
    variables: { enterSoloMomentInput },
    context: { api: ApiLegacyName.Legacy },
  });
  return response.data.enterSoloMoment;
};

export const startLivestream = async (
  startLivestreamInput: IStartLivestreamInput,
): Promise<Mutation["startLivestream"]> => {
  const response = await apolloClient.mutate({
    context: { api: ApiLegacyName.PrimaryLegacy },
    mutation: startLivestreamGQL,
    variables: { startLivestreamInput },
  });

  return response.data.startLivestream;
};

export const joinLivestream = async (
  joinLivestreamInput: IJoinLivestreamInput,
): Promise<Mutation["joinLivestream"]> => {
  const response = await apolloClient.mutate({
    context: { api: ApiLegacyName.PrimaryLegacy },
    mutation: joinLivestreamGQL,
    variables: { joinLivestreamInput },
  });
  return response.data.joinLivestream;
};
