import gql from "graphql-tag";

export const updateUserGQL = gql`
  mutation updateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      message
    }
  }
`;

export const updateUserMetadataGQL = gql`
  mutation UpdateUserMetadata($input: UserMetadataInput!) {
    updateUserMetadata(input: $input) {
      message
    }
  }
`;

export const claimFreeCoinsGQL = gql`
  mutation ClaimFreeCoinsGQL {
    claimFreeCoins {
      message
    }
  }
`;

export const generateUploadURLGQL = gql`
  mutation GenerateUploadURL($input: SignedURLInput!) {
    generateUploadURL(input: $input) {
      url
    }
  }
`;

export const claimLoginRewardGQL = gql`
  mutation Mutation($day: Int!) {
    claimLoginReward(day: $day) {
      message
    }
  }
`;
