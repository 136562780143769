import { QueryOptions } from "@apollo/client/core";
import apollo from "../../../clients/apollo";
import { Query } from "../../graphql";
import {
  getActivitiesGQL,
  getTotalActivitiesGQL,
} from "../../queries/activitiesQueryGql";

const apolloClient = apollo.getInstance();

export const getActivities = async (
  params: {
    category?: string;
    limit?: number;
    offset?: number;
  },
  options: Partial<QueryOptions> = {},
): Promise<Query["me"]["activities"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getActivitiesGQL,
    variables: { input: params },
  });

  return res.data.me.activities;
};

export const getTotalNbActivities = async (
  params: {
    category?: string;
    limit?: number;
    offset?: number;
  },
  options: Partial<QueryOptions> = {},
): Promise<Query["me"]["activities"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getTotalActivitiesGQL,
    variables: { input: params },
  });

  return res.data.me.activities;
};
