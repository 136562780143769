import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AsyncAppThunk, RootState } from "../store";
import { keycloakInstance } from "../clients/keycloak";
import { setNotificationError } from "./alert";

export enum UserState {
  UNLOADED,
  SIGNIN,
  SIGNOUT,
}

export interface KeycloakClient {
  authenticated?: boolean;
  error?: string;
  status: UserState;
}

const initialState: KeycloakClient = {
  authenticated: undefined,
  error: undefined,
  status: UserState.UNLOADED,
};

export const resetKeycloakState = createAction("keycloak/resetState");

export const keycloak = createSlice({
  name: "keycloak",
  initialState,
  reducers: {
    signIn: (state) => {
      state.status = UserState.SIGNIN;
    },
    logout: (state) => {
      state.authenticated = false;
      state.error = undefined;
      state.status = UserState.SIGNOUT;
    },
    initKeycloakSuccess: (state) => {
      state.authenticated = true;
    },
    initKeycloakFailure: (state, action: PayloadAction<string>) => {
      state.authenticated = false;
      state.error = action.payload;
    },
  },
});

export const { signIn, logout, initKeycloakSuccess, initKeycloakFailure } =
  keycloak.actions;

export const logoutKeycloak = (): AsyncAppThunk => async (dispatch) => {
  try {
    await keycloakInstance.logout({ redirectUri: window.location.origin });
    dispatch(logout());
  } catch (e) {
    dispatch(setNotificationError((e as Error)?.message));
  }
};

export const isSignedIn = createSelector(
  (state: RootState) => state.keycloak.status,
  (status: UserState) => status === UserState.SIGNIN,
);

export default keycloak.reducer;
