import { without } from "lodash-es";
import { InventoryCategory, TransactionType } from "../graphql/graphql";

export enum Currency {
  Xxx = "XXX",
  Inr = "INR",
  Btc = "BTC",
}

export enum TransactionStatus {
  Completed = "completed",
  Failed = "failed",
  Pending = "pending",
  Error = "ERROR",
}

export enum ShopTab {
  All = "All",
  Owned = "Owned",
  Bundles = "Bundles",
  Avatars = "Avatars",
}

export const INVENTORIES_CATEGORY = [InventoryCategory.Avatar];

export const LIST_TAB_SHOP = [
  ShopTab.All,
  ShopTab.Owned,
  ShopTab.Bundles,
  ...INVENTORIES_CATEGORY,
];

export const TABS = [
  {
    id: "all_categories",
    title: "tab_All Categories",
  },
  {
    id: `${TransactionType.Battle},${TransactionType.Challenge},${TransactionType.Tournament}`,
    title: "tab_Game Transactions",
  },
  {
    id: without(
      Object.values(TransactionType),
      TransactionType.Battle,
      TransactionType.Challenge,
      TransactionType.Tournament,
    ).join(","),
    title: "tab_Purchase & Bonus",
  },
];

export const INVENTORIES_ORDER = ["Free", "Paid", "Premium"];
