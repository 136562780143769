import { isIOS } from "react-device-detect";
import { AnalyticsGA4 } from "./analyticsGA4";
import { IUserChoice } from "../types/pwa";

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

const UtilsPWA: { deferredPrompt: BeforeInstallPromptEvent | null } = {
  deferredPrompt: null,
};

//
// We put it here to catch the events as soon as possible and not only when the component is mounted
//
window.addEventListener("beforeinstallprompt", (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  UtilsPWA.deferredPrompt = e as BeforeInstallPromptEvent;
});

window.addEventListener("appinstalled", () => {
  // Log install to analytics
  console.log("INSTALL: Success");
});

export class System {
  static isIOSDevice = () => {
    return (
      window.navigator.platform === "iPhone" ||
      window.navigator.platform === "iPad"
    );
  };

  static isDocumentVisible = () => {
    return document.visibilityState === "visible";
  };

  static isDocumentHidden = () => {
    return document.visibilityState === "hidden";
  };

  static isPwaInstalled = () => {
    return window.matchMedia("(display-mode: standalone)").matches;
  };

  static isSupportPushNotification = () => {
    if (!isIOS) return true;
    const versionSupportPushNotification = {
      major: 16,
      minor: 4,
    };
    // check if device is iOS or iPadOS and version is greater than 16.4
    if (/iP(hone|ad)/.test(navigator.platform)) {
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (v) {
        const major = parseInt(v[1], 10);
        const minor = parseInt(v[2], 10);
        return (
          (major > versionSupportPushNotification.major ||
            (major === versionSupportPushNotification.major &&
              minor >= versionSupportPushNotification.minor)) &&
          this.isPwaInstalled() &&
          (this.isIOSDevice() || isIOS)
        );
      }
    }
  };

  static PwaInstall = async (
    t: (text: string) => void,
    setIsInstall?: () => void,
  ) => {
    if (System.isPwaInstalled() || this.isPwaInstalled()) {
      alert(t("common_App is installed"));
      return;
    }
    if (UtilsPWA.deferredPrompt) {
      UtilsPWA.deferredPrompt.prompt();
      UtilsPWA.deferredPrompt.userChoice.then((choiceResult: IUserChoice) => {
        if (choiceResult.outcome === "accepted") {
          alert(t("common_App installed successfully"));
          setIsInstall?.();
        } else {
          console.info("User opted out from installing");
        }
      });
      AnalyticsGA4.installApp();
    } else {
      alert(t("common_App is installed"));
    }
  };

  static isLandscape = () => {
    return Math.abs(window.orientation) === 90;
  };

  static getRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  static getRandomValue = <T>(array: T[]) => {
    return array[Math.floor(Math.random() * array.length)];
  };

  static randomBoolean = () => {
    return Math.random() >= 0.5;
  };

  static isProd = import.meta.env.REACT_APP_ENV === "prod";
}
