import React, {
  FC,
  HTMLAttributes,
  useState,
  useEffect,
  Suspense,
} from "react";
import { OnmoStorage } from "../models/onmoStorage";
import { initializeI18N } from "../i18n";
import i18next from "i18next";
import { Translations } from "../models/translations";
import { OnmoLoading } from "../components/common/OnmoLoading";
import useEventListener from "../hooks/useEventListener";
import { requestFullScreen } from "../models/screen";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Alert } from "../components/core";
import { ErrorScreen } from "../components/common/ErrorScreen";
import { PopupType } from "../constants/popup";
import { AccountBlocked } from "../components/common/AccountBlocked";
import {
  isAccountDisable,
  isErrorMaintenance,
  isErrorTenant,
} from "../models/users/users";
import { useStyles } from "./RoutesLogged.style";
import { setTextStyle } from "../slices/theme";
import { TextStyleByLanguage } from "../constants/user";
import { System } from "../models/system";
import { UserState, logoutKeycloak, signIn } from "../slices/keycloak";
import { login } from "../legacyGraphql/resolvers/mutation/users";
import { updatePermissionGroup } from "../slices/user";
import get from "lodash-es/get";
import { APP_URL } from "../constants/onmo";
import { addPopupQueue } from "../slices/popup";
import { startMaintenance } from "../slices/maintenance";
import { setPercentage } from "../slices/splashScreen";

const RoutesLoggedWithProvider = React.lazy(
  () => import("./RoutesLoggedWithProvider"),
);

export const Routing: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const userStatus = useAppSelector((state) => state.keycloak.status);
  const { theme, isThemeLoaded } = useAppSelector((state) => state.theme);
  const { currentPopupId } = useAppSelector((state) => state.popup);
  const { app, pages } = theme;
  const [isI18NLoaded, setIsI18NLoaded] = useState(false);
  const [isAccountBlock, setIsAccountBlock] = useState(false);
  const isMaintenance = useAppSelector((state) => state.maintenance.isEnabled);
  const maintenanceDetails = useAppSelector((state) => state.maintenance);
  const alert = useAppSelector((state) => state.alert);
  const { authenticated } = useAppSelector((state) => state.keycloak);

  const onRedirectToOnmoPay = () => {
    if (
      pages?.login?.isOnmoPayRedirect &&
      pages?.login?.onmoPayUrl &&
      userStatus === UserState.SIGNOUT &&
      System.isProd &&
      !isMaintenance
    ) {
      // B2B automatic redirect to onmoPay login
      OnmoStorage.setLoginType("onmoPay");
      window.location.href = `${
        pages?.login?.onmoPayUrl
      }&lang=${Translations?.getDefaultLanguage()}`;
    }
  };

  const onBackLogin = () => {
    setIsAccountBlock(false);
  };

  useEffect(() => {
    onRedirectToOnmoPay();
  }, [authenticated, isMaintenance, theme, userStatus]);

  useEffect(() => {
    const onLoaded = () => {
      setIsI18NLoaded(true);
    };
    const onLanguageChanged = (lang: string) => {
      dispatch(setTextStyle(TextStyleByLanguage[lang] || "default"));
    };
    i18next.on("loaded", onLoaded);
    i18next.on("languageChanged", onLanguageChanged);
    initializeI18N();
    return () => {
      i18next.off("loaded", onLoaded);
      i18next.off("languageChanged", onLanguageChanged);
    };
  }, []);

  useEffect(() => {
    const currentAuthenticated = async () => {
      try {
        const oncode =
          new URL(window.location.href).searchParams.get("oncode") || undefined;
        const encryptedOidcState = OnmoStorage.getState();
        const loginRes = await login({ oncode, encryptedOidcState });
        dispatch(
          updatePermissionGroup({
            permissionGroup: loginRes.authUser.permissions,
          }),
        );
        if (!OnmoStorage.getUserId()) {
          OnmoStorage.setIsLogin();
        }
        const redirectUrl = get(
          loginRes,
          "oncodeResponse.result[0].data.redirect",
        );
        if (redirectUrl) {
          OnmoStorage.setDeepLink(`${APP_URL}${redirectUrl}`);
        } else {
          const deepLink = OnmoStorage.getDeepLink();
          if (deepLink) {
            OnmoStorage.removeDeepLink();
            window.location.href = deepLink;
          }
        }
        dispatch(signIn());
        dispatch(setPercentage({ percentage: 50 }));
      } catch (e) {
        console.error("[cognito] User not authenticated", e);
        if (isAccountDisable((e as Error)?.message)) {
          dispatch(setPercentage({ percentage: 100 }));
          setIsAccountBlock(true);
          throw new Error((e as Error)?.message);
        } else if (isErrorTenant(e)) {
          dispatch(addPopupQueue({ type: PopupType.ErrorTenantMismatch }));
        } else if (isErrorMaintenance(e)) {
          dispatch(startMaintenance({ reason: "" }));
        } else {
          dispatch(logoutKeycloak());
        }
        OnmoStorage.setDeepLink();
      }
    };

    if (authenticated) {
      currentAuthenticated();
    }
  }, [authenticated, dispatch]);

  useEffect(() => {
    if (!app?.feature?.forceFullscreenOnFirstTouch) return;
    document.body.addEventListener("click", requestFullScreen);
    return () => {
      document.body.removeEventListener("click", requestFullScreen);
    };
  }, [app?.feature?.forceFullscreenOnFirstTouch]);

  if (currentPopupId === PopupType.ErrorTenantMismatch) {
    return <ErrorScreen type={PopupType.ErrorTenantMismatch} />;
  }

  if (isAccountBlock) {
    return (
      <AccountBlocked className={classes.contentBlock} onBack={onBackLogin} />
    );
  }

  if (userStatus === UserState.UNLOADED || !isThemeLoaded || !isI18NLoaded) {
    return null;
  }

  return (
    <Suspense fallback={<OnmoLoading />}>
      {alert.isShowAlert && (
        <Alert message={alert.message} classify={alert.classify} />
      )}
      {maintenanceDetails.isEnabled && (
        <ErrorScreen type={PopupType.Maintenance} />
      )}
      {authenticated && <RoutesLoggedWithProvider />}
    </Suspense>
  );
};
