import { compact, uniq } from "lodash-es";
import { ONMO_CONFIG } from "./constants/onmo";
import { OnmoStorage } from "./models/onmoStorage";
import TagManager from "react-gtm-module-defer";

const setupAnalytics = (isUseCookie = false) => {
  if (
    !OnmoStorage.getIsUseCookie() ||
    OnmoStorage.getIsUseCookie() === "true" ||
    isUseCookie
  ) {
    const gtmIds = compact(
      uniq([
        ONMO_CONFIG?.analytics?.gtmId,
        ...(ONMO_CONFIG?.analytics?.gtmIds || []),
      ]),
    );
    gtmIds.forEach((gtmId) => {
      const tagManagerArg = {
        gtmId: gtmId,
        dataLayer: {
          environment: ONMO_CONFIG?.analytics?.gtmEnv,
        },
      };
      TagManager.initialize(tagManagerArg);
    });
  }
};

export default setupAnalytics;
