import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import { orderBy, uniqBy } from "lodash-es";

export type MapAnimationSteps =
  | "showConfetti"
  | "mapFadeOut"
  | "finishMapAnimation";

export type AchievementAnimationSteps =
  | "showIncreaseNumber"
  | "showOldBadge"
  | "showAchievementUpgrade"
  | "showGemAchievement";

export type ResultAnimationSteps =
  | "startAnimation"
  | "breakResult"
  | "finishResultAnimation";

export type TournamentAnimationSteps =
  | "startAnimation"
  | "finishTournamentAnimation";

type MapAnimationType = {
  steps: MapAnimationSteps[];
  currentStep: MapAnimationSteps;
};

type AchievementAnimationType = {
  steps: AchievementAnimationSteps[];
  currentStep: AchievementAnimationSteps;
};

type ResultAnimationType = {
  steps: ResultAnimationSteps[];
  currentStep: ResultAnimationSteps;
};

type TournamentAnimationType = {
  steps: TournamentAnimationSteps[];
  currentStep: TournamentAnimationSteps;
};

export const mapAnimationSteps = {
  showConfetti: {
    step: 1,
    timeOut: 100,
  },
  mapFadeOut: {
    step: 2,
    timeOut: 3500,
  },
  finishMapAnimation: {
    step: 3,
    timeOut: 3600,
  },
};

export const resultAnimationSteps = {
  startAnimation: {
    step: 1,
    timeOut: 1000,
  },
  breakResult: {
    step: 2,
    timeOut: 500,
  },
  finishResultAnimation: {
    step: 3,
    timeOut: 1000,
  },
};

export const tournamentAnimationSteps = {
  startAnimation: {
    step: 1,
    timeOut: 1000,
  },
  finishTournamentAnimation: {
    step: 2,
    timeOut: 100,
  },
};

const DEFAULT_ANIMATIONS = {
  mapAnimation: {
    currentStep: "showConfetti",
    steps: ["showConfetti", "mapFadeOut", "finishMapAnimation"],
  },
  resultAnimation: {
    currentStep: "startAnimation",
    steps: ["startAnimation", "breakResult", "finishResultAnimation"],
  },
  tournamentAnimation: {
    currentStep: "startAnimation",
    steps: ["startAnimation", "finishTournamentAnimation"],
  },
  gemAnimation: null, //TODO: define animation steps and currentStep
  achievementAnimation: {
    currentStep: "showIncreaseNumber",
    steps: [
      "showIncreaseNumber",
      "showOldBadge",
      "showAchievementUpgrade",
      "showGemAchievement",
    ],
  },
  rankAnimation: null, //TODO: define animation steps and currentStep
};

type MapAnimation = {
  type: "mapAnimation";
  animation: MapAnimationType;
};

type ResultAnimation = {
  type: "resultAnimation";
  animation: ResultAnimationType;
};

type TournamentAnimation = {
  type: "tournamentAnimation";
  animation: TournamentAnimationType;
};

type GemAnimation = {
  type: "gemAnimation";
  animation: null; //TODO: add animation type
};

type AchievementAnimation = {
  type: "achievementAnimation";
  animation: AchievementAnimationType;
};

type RankAnimation = {
  type: "rankAnimation";
  animation: null; //TODO: add animation type
};

type CurrentAnimation =
  | MapAnimation
  | ResultAnimation
  | TournamentAnimation
  | GemAnimation
  | AchievementAnimation
  | RankAnimation;

type AnimationTypes =
  | { type: "mapAnimation"; order: number }
  | { type: "resultAnimation"; order: number }
  | { type: "tournamentAnimation"; order: number }
  | { type: "gemAnimation"; order: number }
  | { type: "achievementAnimation"; order: number }
  | { type: "rankAnimation"; order: number }
  | undefined;

type IAnimations = {
  animations: AnimationTypes[] | [];
  currentAnimation: CurrentAnimation | null;
  status: "idle" | "ready" | "running" | "finished";
};

export const resetAnimationsState = createAction("animations/resetState");

const initialState = {
  animations: [],
  currentAnimation: null,
  status: "idle",
} as IAnimations;

export const animationsSlice = createSlice({
  name: "animations",
  initialState: initialState,
  reducers: {
    setCurrentAnimation: (
      state,
      action: PayloadAction<{ animation: CurrentAnimation | null }>,
    ) => {
      if (state.status !== "finished") {
        state.currentAnimation = action.payload.animation;
      }
    },
    playNextAnimation: (state) => {
      const animations = state.animations;
      const nextAnimationObj = animations[0];
      if (!nextAnimationObj && ["ready", "running"].includes(state.status)) {
        setCurrentAnimation({ animation: null });
        state.status = "finished";
      } else if (nextAnimationObj && state.status !== "finished") {
        const nextAnimation = {
          type: nextAnimationObj.type,
          animation: DEFAULT_ANIMATIONS[nextAnimationObj.type],
        };
        state.status = "running";
        state.currentAnimation = nextAnimation as CurrentAnimation;
        state.animations = state.animations.filter(
          (animation) => animation?.type !== state.currentAnimation?.type,
        );
      }
    },
    pushAnimation: (
      state,
      action: PayloadAction<{
        animationType:
          | "mapAnimation"
          | "resultAnimation"
          | "tournamentAnimation"
          | "gemAnimation"
          | "achievementAnimation"
          | "rankAnimation";
        order: number;
      }>,
    ) => {
      const animation = {
        type: action.payload.animationType,
        order: action.payload.order,
      };
      const animations = [...state.animations, animation];
      state.animations = orderBy(
        uniqBy(animations, "type"),
        ["order"],
        ["asc"],
      );
    },
    setAnimationStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "ready" | "running" | "finished";
      }>,
    ) => {
      const { status } = action.payload;
      if (state.animations.length === 0 && status == "ready") {
        return;
      }
      state.status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAnimationsState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  playNextAnimation,
  setCurrentAnimation,
  setAnimationStatus,
  pushAnimation,
} = animationsSlice.actions;

export const setCurrentAnimationStep =
  (step: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const currentAnimation = state.animations.currentAnimation;
    if (!currentAnimation?.animation) return;
    const updatedMapAnimation = {
      ...currentAnimation,
      animation: {
        ...currentAnimation.animation,
        currentStep: step,
      },
    };
    dispatch(
      setCurrentAnimation({
        animation: updatedMapAnimation as CurrentAnimation,
      }),
    );
  };

// map Animation
export const isRunMapAnimationSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.status === "running" &&
    animations.currentAnimation?.type === "mapAnimation" &&
    animations.currentAnimation.animation?.currentStep !== "finishMapAnimation",
);

export const isMapFadeOutSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.currentAnimation?.type === "mapAnimation" &&
    animations.currentAnimation.animation?.currentStep === "mapFadeOut",
);

export const isFinishAnimationSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.status === "finished" ||
    (!animations.animations.length && animations.status === "idle") ||
    (animations.animations.length === 0 &&
      animations.currentAnimation === null),
);

// achievement Animation

export const isShowAchievementAnimationSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.status === "running" &&
    animations.currentAnimation?.type === "achievementAnimation",
);

export const showGemRewardSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.status === "finished" ||
    (!animations.animations?.length && animations.status === "idle") ||
    animations.currentAnimation?.type === "achievementAnimation" ||
    animations.currentAnimation?.type === "rankAnimation",
);

export const showGemAchievementSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.currentAnimation?.type === "achievementAnimation" &&
    animations.currentAnimation.animation.currentStep === "showGemAchievement",
);

export const hasAchievementUpgradeSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) => {
    return !!animations.animations.find(
      (animation) => animation?.type === "achievementAnimation",
    );
  },
);

// result animation
export const isResultFadeOutSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.status === "running" &&
    animations.currentAnimation?.type === "resultAnimation" &&
    animations.currentAnimation.animation?.currentStep ===
      "finishResultAnimation",
);

export const isStartAnimationResultSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.status === "running" &&
    animations.currentAnimation?.type === "resultAnimation" &&
    animations.currentAnimation.animation?.currentStep === "startAnimation",
);

// tournament animation
export const isTournamentFadeOutSelector = createSelector(
  (state: RootState) => state.animations,
  (animations: IAnimations) =>
    animations.status === "running" &&
    animations.currentAnimation?.type === "tournamentAnimation" &&
    animations.currentAnimation.animation?.currentStep ===
      "finishTournamentAnimation",
);

export default animationsSlice.reducer;
