import { TFunction } from "i18next";
import { HistoryOnmo } from "./historyOnmo";
import { getGameById } from "./game/game";
import { AnalyticsGA4 } from "./analyticsGA4";
import { History } from "history";
import {
  AchievementType,
  AchievementUserNotificationData,
  BattleUserNotificationData,
  FollowUserNotificationData,
  Game,
  LeaderboardUserNotificationData,
  TournamentUserNotificationData,
  UserNotification,
  UserNotificationType,
} from "../graphql/graphql";

const Leaderboard_Type = {
  GLB_CONTEST: "global-leaderboard-contests",
  GLB_WEEKLY: "global-leaderboard-weekly",
  GLB_MONTHLY: "global-leaderboard-monthly",
};

export class NotificationFactory {
  static renderNotificationType = (data: UserNotification, t: TFunction) => {
    switch (data.type) {
      case UserNotificationType.AchievementReward: {
        return t("new_achievement");
      }
      case UserNotificationType.FollowedByUser: {
        return t("common_New Follower");
      }
      case UserNotificationType.BattleInvitation: {
        return t("battle_invitation");
      }
      case UserNotificationType.BattleExpiring: {
        return t("battle_expiring");
      }
      case UserNotificationType.BattleWon: {
        return t("battle_won");
      }
      case UserNotificationType.BattleLost: {
        return t("battle_lost");
      }
      case UserNotificationType.BattleExpired: {
        return t("battle_expired");
      }
      case UserNotificationType.TournamentCompletion: {
        return t("tournament_ended");
      }
      case UserNotificationType.GlobalLeaderboardEnded: {
        const lbData = data.data as LeaderboardUserNotificationData;
        if (lbData.leaderboardType === Leaderboard_Type.GLB_WEEKLY) {
          return t("weekly_leaderboard");
        } else if (lbData.leaderboardType === Leaderboard_Type.GLB_MONTHLY) {
          return t("monthly_leaderboard");
        }
      }
    }
  };

  static getAchievementNameByType = (type: AchievementType) => {
    switch (type) {
      case AchievementType.BattlesWon:
        return "achievement_Conqueror";
      case AchievementType.BattleStreak:
        return "achievement_Unstoppable";
      case AchievementType.Challenger:
        return "achievement_Challenger";
      case AchievementType.Completionist:
        return "achievement_Mastery";
      case AchievementType.TournamentsParticipation:
        return "achievement_Gladiator";
      case AchievementType.BattleFriendsPlayed:
        return "achievement_Showdown";
      case AchievementType.WeeklyGlParticipant:
        return "achievement_Contender";
      case AchievementType.WeeklyGlWinner:
        return "achievement_Dominator";
      case AchievementType.Followers:
        return "achievement_Influencer";
      default:
        return "new_achievement";
    }
  };

  static renderNotiDescription = (
    data: UserNotification,
    userId: string | undefined,
    t: TFunction,
  ) => {
    const battleUserData = data.data as BattleUserNotificationData;
    const opponentBattle =
      battleUserData.host?.id === userId
        ? battleUserData.opponent
        : battleUserData.host;
    const followerUser = data.data as FollowUserNotificationData;
    switch (data.type) {
      case UserNotificationType.AchievementReward: {
        const achievementData = data.data as AchievementUserNotificationData;
        return t(this.getAchievementNameByType(achievementData.type));
      }
      case UserNotificationType.FollowedByUser: {
        return followerUser.followerUser.username;
      }
      case UserNotificationType.BattleInvitation: {
        return opponentBattle?.username;
      }
      case UserNotificationType.BattleExpiring: {
        return opponentBattle?.username;
      }
      case UserNotificationType.BattleWon: {
        return opponentBattle?.username;
      }
      case UserNotificationType.BattleLost: {
        return opponentBattle?.username;
      }
      case UserNotificationType.BattleExpired: {
        return opponentBattle?.username;
      }
      case UserNotificationType.TournamentCompletion: {
        const tournamentUserNoti = data.data as TournamentUserNotificationData;
        return `#${tournamentUserNoti.rank}`;
      }
      case UserNotificationType.GlobalLeaderboardEnded: {
        const leaderboardUserNoti =
          data.data as LeaderboardUserNotificationData;
        return `#${leaderboardUserNoti.rank}`;
      }
    }
  };

  static onClickNotification = (
    data: UserNotification,
    listGame: Game[] | null,
    userId: string,
    history: History<unknown>,
  ) => {
    switch (data.type) {
      case UserNotificationType.BattleInvitation: {
        const battleUserNotifData = data?.data as BattleUserNotificationData;
        return HistoryOnmo.navigateToPlayBattlePage(
          history,
          {
            gameId: battleUserNotifData.gameId,
          },
          {
            matchId: battleUserNotifData.matchId,
            id: battleUserNotifData.host.id,
            username: battleUserNotifData.host.username,
            avatar: battleUserNotifData.host.avatar,
            xp: battleUserNotifData.host.xp,
          },
        );
      }
      case UserNotificationType.BattleExpiring: {
        const battleUserNotifData = data?.data as BattleUserNotificationData;
        return HistoryOnmo.navigateToPlayBattlePage(
          history,
          {
            gameId: battleUserNotifData.gameId,
          },
          {
            matchId: battleUserNotifData.matchId,
            id: battleUserNotifData.host.id,
            username: battleUserNotifData.host.username,
            avatar: battleUserNotifData.host.avatar,
            xp: battleUserNotifData.host.xp,
          },
        );
      }
      case UserNotificationType.BattleExpired: {
        const battleUserNotifData = data.data as BattleUserNotificationData;
        const currentGame = getGameById(listGame, battleUserNotifData.gameId);
        const opponent =
          battleUserNotifData.host?.id === userId
            ? battleUserNotifData.opponent
            : battleUserNotifData.host;
        AnalyticsGA4.momentLoad({
          game_title: currentGame?.title,
          game_type: currentGame?.type,
          moment_type: "Battle",
        });
        return HistoryOnmo.navigateToPlayBattlePage(
          history,
          {
            gameId: battleUserNotifData.gameId,
          },
          {
            id: opponent?.id,
            avatar: opponent?.avatar,
            username: opponent?.username,
            xp: opponent?.xp,
          },
        );
      }
      case UserNotificationType.FollowedByUser: {
        const followerNotifData = data.data as FollowUserNotificationData;
        return HistoryOnmo.navigateToFriendProfilePage(
          history,
          followerNotifData.followerUser.id,
        );
      }
      case UserNotificationType.TournamentCompletion: {
        const tournamentUserNotifData =
          data.data as TournamentUserNotificationData;
        return HistoryOnmo.navigateToMyProfilePage(
          history,
          {
            notificationData: {
              battleId: tournamentUserNotifData.tournamentId,
              gameId: tournamentUserNotifData.gameId,
              battleType: "tournament",
            },
          },
          { tab: "Recent Activities" },
        );
      }
      case UserNotificationType.GlobalLeaderboardEnded: {
        const leaderboardUserNotifData =
          data.data as LeaderboardUserNotificationData;
        return HistoryOnmo.navigateToMyProfilePage(
          history,
          {
            leaderBoardData: leaderboardUserNotifData,
          },
          { tab: "Recent Activities" },
        );
      }
      case UserNotificationType.AchievementReward: {
        const achievementRewardData =
          data.data as AchievementUserNotificationData;
        return HistoryOnmo.navigateToMyProfilePage(
          history,
          {
            achievement: {
              type: achievementRewardData.type,
              tier: achievementRewardData.balance,
            },
          },
          { tab: "Achievements" },
        );
      }
      default:
        return HistoryOnmo.navigateToMyProfilePage(
          history,
          {},
          {
            tab: "Recent Activities",
          },
        );
    }
  };

  static isUserDeleted = (notification?: UserNotification) => {
    if (!notification) return true;
    if (notification.type.includes("BATTLE")) {
      return (
        !(notification?.data as BattleUserNotificationData)?.host ||
        !(notification?.data as BattleUserNotificationData)?.opponent
      );
    }
    if (notification.type === UserNotificationType.FollowedByUser) {
      return !(notification?.data as FollowUserNotificationData).followerUser;
    }
    return false;
  };
}
