import dateFormat from "dateformat";
import { t, TFunction } from "i18next";
import { OnmoStorage } from "./onmoStorage";
import { UserStatus } from "../constants/user";

export class Time {
  static formatDurationSecInMinSec = (duration: number) => {
    if (duration === -1) {
      return "00:00";
    }
    const minute = String(Math.floor(duration / 60)).padStart(2, "0");
    const second = String(duration % 60).padStart(2, "0");
    return `${minute}:${second}`;
  };

  static formatDurationScore = (duration: number) => {
    const minute = String(Math.floor(duration / 60)).padStart(2, "0");
    const second = String(duration % 60).padStart(2, "0");
    return `${minute}:${second}`;
  };

  static getTimeLeft = (endtime: Date | number | string) => {
    const timeNow = new Date();
    const targetDate = new Date(endtime);
    return targetDate.getTime() - timeNow.getTime();
  };

  static countDown = (
    endtime?: Date | number | string,
    isLeaderboard?: boolean,
  ) => {
    if (!endtime) return "";
    const timeLeft = this.getTimeLeft(endtime);
    if (timeLeft < 0) {
      return isLeaderboard ? t("common_Ended") : "00:00:00";
    }

    let seconds: string | number = Math.floor((timeLeft / 1000) % 60);
    let minutes: string | number = Math.floor((timeLeft / 1000 / 60) % 60);
    let hours: string | number = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    const days: string | number = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const daysLeft = days + " " + t("time_days");

    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    if (isLeaderboard) {
      if (days > 0) {
        return t("common_Ends in") + ": " + daysLeft;
      } else {
        return (
          t("common_Ends in") + ": " + hours + ":" + minutes + ":" + seconds
        );
      }
    } else return days > 0 ? daysLeft : hours + ":" + minutes + ":" + seconds;
  };

  static countDownMWCLeaderboard = (endtime?: Date | number | string) => {
    if (!endtime) return "";
    const timeLeft = this.getTimeLeft(endtime);
    if (timeLeft < 0) return "00:00:00";

    let seconds: string | number = Math.floor((timeLeft / 1000) % 60);
    let minutes: string | number = Math.floor((timeLeft / 1000 / 60) % 60);
    let hours: string | number = Math.floor(timeLeft / (1000 * 60 * 60));

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  static getEndTime = (endtime?: Date | number | string) => {
    if (!endtime) return "";

    const timeLeft = this.getTimeLeft(endtime);

    const hours: string | number = Math.floor(
      (timeLeft / (1000 * 60 * 60)) % 24,
    );
    const days: string | number = Math.floor(timeLeft / (1000 * 60 * 60 * 24));

    if (days < 1) {
      if (hours < 0) {
        return "";
      }
      return t("time_hours to go", { hours: hours });
    } else if (days > 30) {
      const endTimeMonth = new Date(endtime).getMonth();
      const nowMonth = new Date(Date.now()).getMonth();
      if (endTimeMonth > nowMonth)
        return t("time_months to go", { months: endTimeMonth - nowMonth });
      //over a year
      else
        return t("time_months to go", { months: endTimeMonth + 12 - nowMonth });
    } else {
      return t("time_days to go", { days: days });
    }
  };

  static countDayLeft = (endtime: string, startTime?: string) => {
    const now = startTime
      ? new Date(startTime).getTime()
      : new Date().getTime();
    const oneDay = 24 * 3600 * 1000;
    const expireDate = new Date(endtime).getTime();
    const daysLeft = Math.round((expireDate - now) / oneDay);

    return {
      unit: "day",
      value: daysLeft,
    };
  };

  static countDayAgo = (time?: string | null) => {
    if (!time) return 0;
    const now = Date.now();
    const oneDay = 24 * 3600 * 1000;
    const startTime = new Date(time).getTime();
    const daysLeft = Math.floor((now - startTime) / oneDay);

    return daysLeft;
  };

  static formatTimePlayedInMinSec = (timePlayed: number, isHtml?: boolean) => {
    if (timePlayed <= 0) {
      return "00 : 00";
    }
    let mins: string | number = isHtml
      ? Math.floor(timePlayed / 60)
      : Math.floor(timePlayed / 1000 / 60);
    if (mins < 10) {
      mins = "0" + mins;
    }
    let secs: string | number = isHtml
      ? Math.floor(timePlayed % 60)
      : Math.floor((timePlayed / 1000) % 60);
    if (secs < 10) {
      secs = "0" + secs;
    }
    return mins + " : " + secs;
  };

  static formatTimeScore = (totalTime: number, timeLeft: number) => {
    const timeScore = totalTime * 1000 - timeLeft;
    return Math.round(timeScore / 1000);
  };

  static formatDate = (
    date: Date | string | number,
    formatType: string,
    isWhatOn: boolean,
  ) => {
    const fDate = new Date(date);
    const diffDays = new Date().getDate() - fDate.getDate();
    const diffMonths = new Date().getMonth() - fDate.getMonth();
    const diffYears = new Date().getFullYear() - fDate.getFullYear();

    if (diffDays === 0 && diffMonths === 0 && diffYears === 0) {
      if (isWhatOn) {
        return "Recent";
      } else return "Today";
    }
    return dateFormat(date, formatType);
  };

  static isOver1Day = (time?: string | null) => {
    const oneDay = 1000 * 60 * 60 * 24;
    return !time || Date.now() - parseInt(time) > oneDay;
  };

  static formatDateGetCoin = (time: string) => {
    const dateFormat = new Date(time);

    return (
      ("00" + (dateFormat.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + dateFormat.getDate()).slice(-2) +
      "/" +
      dateFormat.getFullYear() +
      " " +
      ("00" + dateFormat.getHours()).slice(-2) +
      ":" +
      ("00" + dateFormat.getMinutes()).slice(-2) +
      ":" +
      ("00" + dateFormat.getSeconds()).slice(-2)
    );
  };

  static isExpireOncode = () => {
    const { expire, oncode } = OnmoStorage.getShareOncode();
    if (!expire || !oncode) return true;
    const currentDate = new Date().getTime();
    return currentDate > expire;
  };

  static convertTimestampToFormattedDate = (
    timestamp: string | number,
    isFormatDateFromUI?: boolean,
  ): string => {
    const formatDate = (date: Date): string => {
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = date.getUTCDate().toString().padStart(2, "0");
      return isFormatDateFromUI
        ? `${day}/${month}/${year}`
        : `${year}-${month}-${day}`;
    };

    const date = new Date(timestamp);
    return formatDate(date);
  };

  static getUserStatus = (endtime?: Date | number | string) => {
    if (!endtime) return UserStatus.Offline;

    const timeLeft = -this.getTimeLeft(endtime);
    const minutes: string | number = Math.floor(timeLeft / (1000 * 60));

    if (minutes < 10) {
      return UserStatus.Online;
    } else if (minutes < 60) {
      return UserStatus.Away;
    } else {
      return UserStatus.Offline;
    }
  };

  static isStartNewDay = () => {
    const oldDate = OnmoStorage.getLastDayShowPwa();
    const currentDate = new Date().toLocaleString().split(",")[0];
    return oldDate !== currentDate;
  };

  static convertLiveTime = (startedAt: string, t: TFunction) => {
    const pastDate = new Date(startedAt);
    const now = new Date();
    const diff = now.getTime() - pastDate.getTime();
    const diffMinutes = diff < 60000 ? 1 : Math.floor(diff / 60000);
    const diffHours = Math.floor(diff / 3600000);
    const diffDays = Math.floor(diff / 86400000);

    if (diffDays > 0) {
      return diffDays > 1
        ? t("common_number day ago_other", { count: diffDays })
        : t("common_number day ago_one", { count: diffDays });
    } else if (diffHours > 0) {
      return t("{{x}} hour ago", { x: diffHours });
    } else if (diffMinutes > 0) {
      return t("common_number mins ago", { x: diffMinutes });
    }
  };

  static isExpired(expiredTime: string) {
    return new Date(expiredTime).getTime() < new Date().getTime();
  }

  static parseTimeComponents = (time: string) => {
    const [hours, minutes, seconds] = time.split(":");
    return [
      { title: "Hours", value: hours },
      { title: "Minutes", value: minutes },
      { title: "Seconds", value: seconds },
    ];
  };
}
