import { gql } from "@apollo/client";

export const globalLeaderboardsGQL = gql`
  query Leaderboard($input: LeaderboardInput!) {
    leaderboard(input: $input) {
      endDate
      rank
      score
      total
      items {
        rank
        score
        user {
          id
          username
          avatar
          xp
        }
      }
    }
  }
`;
